/***
 * Select Input for "organization type"
 * @file OrgTypesSelectInput.js
 * @description OrgTypesSelectInput component
 * @author Utkarsh Gupta
 * @since 16 Jan 2023
 */

import React from "react";
import SelectInput from "../SelectInput";
import PropTypes from 'prop-types';

/// Select Input for "organization type"
const OrgTypesSelectInput = (props) => {
  return (
    <SelectInput isrequired name="orgTypeId" label={props.label.length > 0 ? props.label : "Organization Type"} disabled={props.disabled}>
      {
        props.orgTypes
          .filter(type => type.orgType.toLowerCase() !== "shasta")
          .reverse()
          .map(type => {
            if (type.orgTypeId > props.orgTypeId)
              return (
                <option key={type.orgTypeId.toString()} value={type.orgTypeId.toString()}>
                  {type.orgType}
                </option>
              );
          })
      }
    </SelectInput>
  );
};

OrgTypesSelectInput.propTypes = { orgTypes: PropTypes.object, label: PropTypes.string };

OrgTypesSelectInput.defaultProps = { label: "", disabled: false };

export default OrgTypesSelectInput;
