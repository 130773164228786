import { createSlice, current } from "@reduxjs/toolkit";
import { SWITCH } from "../../utility/constants";

const deviceInitialState = {
  status: {
    loading: false,
    error: null,
  },
  data: [],
  offset: 0,
  socketedData: [],
  quickFilterCounts: {},
  ofOrgId: null,
  listType: null,
  connectionType: null,
  search: "",
  filterData: {
    venues: [],
    networks: [],
    bands: [],
    // infra: []
  },
  filterActive: false,
  // controller: null
  fetchCount: false,
  deleted: {}
};

const deviceSlice = createSlice({
  name: "device",
  initialState: { ...deviceInitialState },
  reducers: {
    addItems: (state, action) => {
      const { data } = action.payload;
      state.offset = state.offset + data.length;
      if(!['connected','disconnected'].includes(state.listType)) {
        state.data = [...(state.data??[]), ...data]
        return;
      }
      const newSocketData = [...(state.socketedData??[])];
      const newData = [...(state.data??[])];

      for(let i=0;i<data.length;i++) {
        let socketedDataIndex = newSocketData.findIndex(
          (item) => item.clientMac === data[i].clientMac
        )
        if(socketedDataIndex !== -1) {
          newSocketData[socketedDataIndex] = data[i]
        }
        let dataIndex = newData.findIndex(
          (item) => item.clientMac === data[i].clientMac
        )
        if(dataIndex !== -1) {
          newData[dataIndex] = data[i]
        }
        if(socketedDataIndex === -1 && dataIndex === -1) {
          newData.push(data[i])
        }
      }
      state.socketedData = newSocketData
      state.data = newData
      // state.ofOrgId = action.payload.orgId
    },
    setItems: (state, action) => {
      state.data = action.payload;
      state.offset = action.payload.length;
      // state.socketedData = [];
    },
    reset: (state) => {
      return { ...deviceInitialState };
    },
    setLoading: (state, action) => {
      // if(!!state.controller?.abort) {
      //   state.controller.abort()
      // }
      // state.controller = deviceInitialState.controller
      state.status.loading = action.payload;
    },
    setError: (state, action) => {
      state.status.error = action.payload;
    },
    handleSocketData: (state, action) => {
      if (state.filterActive || state.status.loading) return state;
      
      let dataToAdd = action.payload;
      let type = dataToAdd.socketType;
      let disableCountUpdate = dataToAdd.disableCountUpdate;
      let appendDataList = dataToAdd.appendDataList

      const adder = () => {
        let switchObj = {};
        if (!!dataToAdd.infraCategory && dataToAdd.infraCategory == SWITCH) {
          switchObj = {
            name: dataToAdd.infraName,
            port: dataToAdd.port,
            vlan: dataToAdd.vlan,
            infra_id: dataToAdd.infraId,
            device_serial: dataToAdd.macAddress,
          };
          delete dataToAdd.port;
          delete dataToAdd.vlan;
          delete dataToAdd.infraId;
          delete dataToAdd.infraName;
          delete dataToAdd.macAddress;

          let switches = [switchObj];
          dataToAdd.switches = switches;
        }

        state.deleted[dataToAdd.clientMac] = false

        //combine existing data with new data
        let clientDataInSocketList = state.socketedData?.find(
          (item) => {
            let socketCondition = item?.clientMac === dataToAdd?.clientMac
            return socketCondition
          }
        );
        if(clientDataInSocketList !== undefined)
          clientDataInSocketList = {...clientDataInSocketList}
        let clientDataInDataList = state.data?.find(
          (item) => {
            let normalDataCondition = item?.clientMac === dataToAdd?.clientMac
            return normalDataCondition
          }
        );
        if(clientDataInDataList !== undefined)
          clientDataInDataList = {...clientDataInDataList}
        let currentDataInStore = null;
        if (!!clientDataInSocketList) {
          currentDataInStore = { ...clientDataInSocketList };
        }
        if (!!clientDataInDataList) {
          currentDataInStore = !!currentDataInStore
            ? { ...currentDataInStore, ...clientDataInDataList }
            : { ...clientDataInDataList };
        }
        if(!!currentDataInStore) {
          //merge data
          if (!!currentDataInStore.switches) {
            dataToAdd.switches = [
              ...currentDataInStore.switches.filter(
                (switchItem) => switchItem.infra_id != switchObj.infra_id
              ),
              ...(dataToAdd.switches ?? []),
            ];
          }

          //add in place
          dataToAdd = { ...currentDataInStore, ...dataToAdd };
          let dataListIndex = state.data.findIndex((client) => client.clientMac === dataToAdd.clientMac)
          let socketListIndex = state.socketedData.findIndex((client) => client.clientMac === dataToAdd.clientMac)
          const sameIp = (data1, data2) => {
            const extractIp = (data) => {
              const ip = data.ipv4Address && data.ipv4Address.length > 0
              ? data.ipv4Address[0]
              : data.ipv6Address && data.ipv6Address.length > 0
              ? data.ipv6Address[0]
              : null
              return ip;
            }
            const ip1 = extractIp(data1)
            const ip2 = extractIp(data2)
            return ip1 === ip2
          }
          if(dataListIndex !== -1) {
            if(!sameIp(state.data[dataListIndex], dataToAdd))
              dataToAdd.ipAddressAt = Date.now()
            state.data[dataListIndex] = dataToAdd
          }
          else if(socketListIndex !== -1) {
            if(!sameIp(state.socketedData[socketListIndex], dataToAdd))
              dataToAdd.ipAddressAt = Date.now()
            state.socketedData[socketListIndex] = dataToAdd
          }
        }
        else {
          //add afresh to socket/data list
          let socketListWithoutClient = state.socketedData.filter(
            (client) => client.clientMac !== dataToAdd.clientMac
          );
          //filter from normal data list
          let dataListWithoutClient = state.data.filter(
            (client) => client.clientMac !== dataToAdd.clientMac
          );
          if(appendDataList) {
            state.data = [...dataListWithoutClient, dataToAdd]
          }
          else
            state.socketedData = [...socketListWithoutClient, dataToAdd];
          

          //change quick filter counts
          if (
            !!state.quickFilterCounts && !disableCountUpdate && ["wired","wireless"].includes(dataToAdd.clientType)
          ) {
            state.quickFilterCounts[dataToAdd.clientType] = ((state.quickFilterCounts[dataToAdd.clientType] ?? 0) + 1);
            if(state.listType === 'disconnected') {
              state.quickFilterCounts['historical'] = ((state.quickFilterCounts['historical'] ?? 0) + 1)
            }
          }
        }
      };

      const deleter = () => {
        let actuallyDeleted = false;
        if(!!state.socketedData.find(client => client.clientMac === action.payload.clientMac))
          actuallyDeleted = true;
        if(!!state.data.find(client => client.clientMac === action.payload.clientMac))
          actuallyDeleted = true;
        state.socketedData = state.socketedData.filter(
          (item) => item.clientMac !== action.payload.clientMac
        );
        state.data = state.data.filter(
          (item) => item.clientMac !== action.payload.clientMac
        );

        if(state.deleted[action.payload.clientMac] === true) {
          return
        }

        if(actuallyDeleted) {
          state.offset = Math.max(state.offset - 1,0);
        }
        
        //change quick filter counts
        if (
          !!state.quickFilterCounts && !disableCountUpdate && ['wired','wireless'].includes(action.payload.clientType)
        ) {
          state.quickFilterCounts[action.payload.clientType] = Math.max(
            ((state.quickFilterCounts[action.payload.clientType] ?? 0) - 1),
            0
          );
          if(state.listType === 'disconnected') {
            state.quickFilterCounts['historical'] =Math.max(
              ((state.quickFilterCounts['historical'] ?? 0) - 1),
              0
            )
          }
        }
      };

      const hasSearchTerm = (searchTarget) => {
        // return true;
        if (state.search.length < 2) {
          return true;
        } else {
          let sterm = state.search.toLowerCase();
          if (
            searchTarget?.clientName?.includes(sterm) ||
            searchTarget?.mac
              ?.replace(/:/g, "")
              ?.includes(sterm.replace(/:/g, "")) ||
            // searchTarget?.deviceName?.includes(sterm) ||
            searchTarget?.ssid?.includes(sterm) ||
            searchTarget?.infraName?.includes(sterm) ||
            searchTarget?.venueName?.includes(sterm)
          ) {
            return true;
          } else {
            return false;
          }
        }
      };

      if (
        (state.connectionType === "all" ||
          state.connectionType === dataToAdd.clientType) &&
        hasSearchTerm(dataToAdd) &&
        ['connected','disconnected'].includes(state.listType) 
      ) {
        //type and state.listType are connected/disconnected
        if (type === state.listType) {
          //add normally
          adder();
        } else {
          //delete normally
          deleter();
        }
      }
      return state;
    },
    setSocketData: (state, action) => {
      state.socketedData = action.payload;
      return state;
    },
    resetSocketData: (state, action) => {
      state.socketedData = deviceInitialState.socketedData;
      return state;
    },
    setListType: (state, action) => {
      state.listType = action.payload;
      return state;
    },
    setConnectionType: (state, action) => {
      state.connectionType = action.payload;
      return state;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
      return state;
    },
    setFilterData: (state, action) => {
      state.filterData = action.payload;
      return state;
    },
    setFilterActive: (state, action) => {
      state.filterActive = action.payload;
      return state;
    },
    setQuickFilterCounts: (state, action) => {
      let payloadObj = action.payload ?? {}
      Object.keys(payloadObj).map(countType => {
        payloadObj[countType] = Math.max(payloadObj[countType], 0)
      })
      state.quickFilterCounts = !!state.quickFilterCounts
        ? { ...state.quickFilterCounts, ...payloadObj }
        : payloadObj;
      return state;
    },
    setCountFetch: (state, action) => {
      state.fetchCount = action.payload;
      return state;
    }
    // setController: (state, action) => {
    //   state.controller = action.payload
    //   return state
    // }
  },
});

export const deviceReducer = deviceSlice.reducer;
const deviceActions = deviceSlice.actions;
export default deviceActions;
