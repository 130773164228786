/***
 *
 * Controller class for user.
 * @file OrgWidget.js
 * @description OrgWidget component
 * @author Utkarsh Gupta
 * @since 12 Jul 2022
 *
 */

import React, {
  Suspense,
  createContext,
  lazy,
  memo,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
// import PropTypes from 'prop-types';
import "./OrgWidget.scss";
import {
  Button,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  InputGroup,
  Row,
  Spinner,
  Table,
  UncontrolledDropdown,
} from "reactstrap";
import SearchIconAddon from "../SearchIconAddon";
import GroupButton from "../GroupButton";
import { allowedColumnsObj, apiColumns, columnObj, filterObj, LIST_TYPES, mapColumns } from "./utils";
import FilterButton from "../FilterButton";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  ALERT_HIG,
  ALERT_MED,
  BUSINESS,
  BUSINESS_VIEW,
  BUS_STATE_MAPPING,
  DEPLOYMENT_VIEW,
  MSP,
  MSP_STATE_MAPPING,
  THIRD_PARTY_TITLE,
  VENUE_STATE,
  firmwareChartKeys,
  firmwareChartLabels,
} from "../../utility/constants";
import ColumnHeader from "../ColumnHeader";
import { ExpCell } from "../ExpTable";
import { CollapseIcon, ExpandIcon } from "../../assets/images/icons/Icons";
import { ReactComponent as Critical } from "../../assets/images/icons/emergency-siren.svg";
import { ReactComponent as Major } from "../../assets/images/icons/OrangeWarning.svg";
import { cmpCol, getColumns, getViewType, setColumns, setViewType } from "../../utility/colSaver";
import {
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { ReactComponent as List } from "../../assets/images/icons/List.svg";
import { ReactComponent as MSPIcon } from "../../assets/images/icons/MSP.svg";
import { ReactComponent as BusIcon } from "../../assets/images/icons/BUS.svg";
import { ReactComponent as OrgIcon } from "../../assets/images/icons/Organization-Black.svg";
import { ReactComponent as Building } from "../../assets/images/icons/Building.svg";
import { ReactComponent as Success } from "../../assets/images/icons/Success.svg";
import {
  ImageTooltip,
  InstallTooltip,
  LayoutTooltip,
  MAC_ADDRESS,
  ValidateTooltip,
} from "../../pages/Venue/Infrastructure";
import { formatNetworkCardData } from "../../pages/Dashboard/NewDashboard/BlazerUtil";
import {
  DowngradeModal,
  VenueIcon,
  statusIcons,
  versionColors,
} from "../../pages/Venue/VenueTree";
import { UncontrolledTooltip } from "reactstrap/lib";
import { OrgDeleteModal } from "../../pages/Organization/OrgList";
import createRequest, { services } from "../../services";
import { deleteTypes, Highlight, RowSecondLine } from "../VenueInfraWidget";
import { capitalizeSentence, capitalizeWord, downloadSpreadsheet, scrollToTop } from "../../utility/Utils";
import PendingTaskBreakdown, { LeftBracket, RightBracket } from "../PendingTaskBreakdown";
import { UPTIME } from "../../pages/Infrastructure/Details";
import { CatchedWebError } from "../../configs";
import { createErrorContext } from "../../configs/ErrorContextMaker";
import { TICKET_CATEGORY } from "../../pages/Tickets/TicketConstants";
import { make_custom_toast } from "../../helpers/toasts";
import lodash from "lodash-es";
import {
  CategoryFilter,
  ConnectedFilter,
  FWFilter,
  InfraTypeFilter,
  LocationFilter,
  OperationsFilter,
  OrgFilter,
  OrgStateFilter,
  VenueStateFilter,
} from "../../pages/Filters/filters";
import { handleCopy } from "../../pages/Infrastructure/APOverview";
import { v4 as uuidv4 } from 'uuid';
import { identityActions } from "../../redux/slices";
// import { OrgDeleteModal } from "../../pages/Organization/OrgList";
import { ReactComponent as CsvIconGrey } from "../../assets/images/icons/xls_icon_grey.svg";
import { ReactComponent as CsvIcon } from "../../assets/images/icons/xls_icon.svg";
import PendingTasksSubHeading from "../PendingTasksSubHeading";

const TimeProgress = lazy(() => import("../TimeProgress"));
const LightBadge = lazy(() => import("../LightBadge"));
const APSVG = lazy(() => import("../../pages/Infrastructure/InfraList/APSVG"));
const SwitchSVG = lazy(() => import("../../pages/Infrastructure/InfraList/SwitchSVG"));
const MapWidget = lazy(() => import("../MapWidget"));
const InfraBreakdown = lazy(() => import("../InfraBreakdown"));
const FirmwareReschedule = lazy(() => import("../FirmwareRescheduleVenue"));
const OperationCounters = lazy(() => import("../OperationCounters"));
const FirmwarePopup = lazy(() => import("../FirmwarePopup"));
const FilterSetter = lazy(() => import("../FilterSetter"));
const MoveInfraModal = lazy(() => import("../MoveInfraModal"));
const DeleteInfraModal = lazy(() => import("../DeleteInfraModal"));
const DeleteModal = lazy(() => import("../DeleteModal"));
const Copy = lazy(() => import("../../pages/Infrastructure/APOverview/Copy"));
const BulkActions = lazy(() => import("../BulkActions"));
const SidePanel = lazy(() => import("../SidePanel"));
const ColSelector = lazy(() => import("../ColSelector"));
const VenueStateGroup = lazy(() => import("../VenueStateGroup"));
const IfVisible = lazy(() => import("../../components/IfVisible"));
const ApexChartsTrendLine = lazy(() => import("../ApexChartsTrendLine"));

export const statusColor = {
  connected: "#13A877",
  disconnected: "#ea5455",
  demo: "#4b4b4b",
  pending: "#ff9f43",
};

export const statusText = {
  connected: "Online",
  disconnected: "Offline",
  demo: THIRD_PARTY_TITLE,
  pending: "Pending",
};

export const orgtypesIcons = {
  1: (
    <MSPIcon
      width="100%"
      height="100%"
      style={{ height: "16px", width: "16px" }}
    />
  ),
  2: (
    <MSPIcon
      width="100%"
      height="100%"
      style={{ height: "16px", width: "16px" }}
    />
  ),
  3: (
    <BusIcon
      width="100%"
      height="100%"
      style={{ height: "16px", width: "16px" }}
    />
  ),
};

export const ipFormater = (ip = "") => {
  let ipSegments = ip.split(".");

  if (ipSegments.length < 4) return "";

  return (
    <span>
      {ipSegments[0]}.
      <span className={ipSegments[0] == 10 ? "font-weight-bolder" : ""}>
        {ipSegments[1]}.
      </span>
      <span
        className={
          ipSegments[0] == 10 ||
            (ipSegments[0] == 192 && ipSegments[1] == 168) ||
            (ipSegments[0] == 172 && ipSegments[1] >= 16 && ipSegments[1] <= 32)
            ? "font-weight-bolder"
            : ""
        }
      >
        {ipSegments[2]}.
      </span>
      <span
        className={
          ipSegments[0] == 10 ||
            (ipSegments[0] == 192 && ipSegments[1] == 168) ||
            (ipSegments[0] == 172 && ipSegments[1] >= 16 && ipSegments[1] <= 32)
            ? "font-weight-bolder"
            : ""
        }
      >
        {ipSegments[3]}
      </span>
    </span>
  );
};

export const radioFormater = (radio) => {
  let str = "";

  if (!radio.supported || radio.active == null) return str;
  if (!radio.active)
    return (
      <div className="d-flex">
        <Suspense fallback={<></>}>
          <LightBadge color="secondary">Disabled</LightBadge>
        </Suspense>
      </div>
    );

  if (!!radio.channel) str += `Ch ${radio.channel}`;
  if (!!radio.channel_width) {
    if (str.length) str += ", ";
    str += `${radio.channel_width} MHz`;
  }
  if (!!radio.tx_power) {
    if (str.length) str += ", ";
    str += `${radio.tx_power} dBm TX`;
  }

  return str;
};

/// Input debounce for search box
let timer;
const debounce = (callback) => {
  clearTimeout(timer);
  timer = setTimeout(callback, 1000);
};

const OrgListContext = createContext(null);

const OrgState = (state, typeId) => {
  if (typeId == BUSINESS) {
    return BUS_STATE_MAPPING[state]
  }
  else
    return MSP_STATE_MAPPING[state]
}

const getRowList = (data) => {
  const {
    offset = 0,
    cols,
    listType,
    item,
    view,
    sort,
    subCount,
    setSubList,
    permissions,
    venuePermissions,
    setHasMore,
    mainFilter
  } = data;
  const columns = Object.keys(cols ?? {})
    .map((col) => (cols[col] ? apiColumns[col] ?? col : null))
    .filter((it) => it);
  if (listType == LIST_TYPES.INFRA_LIST && item.venueId != null) {
    const { controller, run } = createRequest(
      services.telemetry.GET_VENUE_CHILDREN, [
      item.venueId,
      offset,
      20,
      "",
      view,
      columns,
      "All",
      sort,
      {},
      true,
      false,
      false
    ]
    )
    run()
      .then((response) => {
        // setSubListError(false);
        subCount.current += response.data.length;
        if (offset == 0) {
          setSubList([...listFormatter(response.data, permissions, venuePermissions)]);
        } else {
          setSubList((prevState) => [...prevState, ...listFormatter(response.data, permissions, venuePermissions)]);
        }

        if (response.data.length == 20) getRowList({ ...data, offset: subCount.current });
        else setHasMore(false);
      })
      .catch((err) => {
        let x = new CatchedWebError(err);
        // setSubListError(x.message);
      })
      .finally(() => {
        // setSubListLoading(false);
      });

    return controller;
  }
  else if ((listType == LIST_TYPES.MSP_LIST || listType == LIST_TYPES.ORG_LIST) && item.venueId == null) {
    const { run } = createRequest(services.telemetry.GET_ORG_LIST, [
      item.orgId,
      offset,
      20,
      "",
      "All",
      sort,
      {},
      columns,
      view,
      listType == LIST_TYPES.MSP_LIST
    ]);

    run()
      .then((response) => {
        // setSubListError(false);
        subCount.current += response.data.length;
        if (offset == 0) {
          setSubList([...listFormatter(response.data, permissions, venuePermissions)]);
        } else {
          setSubList((prevState) => [...prevState, ...listFormatter(response.data, permissions, venuePermissions)]);
        }

        if (response.data.length == 20) getRowList({ ...data, offset: subCount.current });
        else setHasMore(false);
      })
      .catch((err) => {
        let x = new CatchedWebError(err);
        make_custom_toast("error", "Error", x.message)
      })
      .finally(() => {
        // setSubListLoading(false);
      });
  }
  else if (item.orgName) {
    const { controller, run } = createRequest(
      services.telemetry.GET_ORG_VENUE_LIST,
      [
        item.orgId,
        offset,
        20,
        "",
        mainFilter,
        sort,
        "Tree",
        {},
        columns,
        view,
      ]
    );

    run()
      .then((response) => {
        // setSubListError(false);
        subCount.current += response.data.length;
        if (offset == 0) {
          setSubList([...listFormatter(response.data, permissions, venuePermissions)]);
        } else {
          setSubList((prevState) => [...prevState, ...listFormatter(response.data, permissions, venuePermissions)]);
        }

        if (response.data.length == 20) getRowList({ ...data, offset: subCount.current });
        else setHasMore(false);
      })
      .catch((err) => {
        let x = new CatchedWebError(err);
        // setSubListError(x.message);
      })
      .finally(() => {
        // setSubListLoading(false);
      });

    return controller;
  }
  else {
    const { controller, run } = createRequest(
      services.telemetry.GET_VENUE_CHILD,
      [
        item.venueId,
        offset,
        20,
        "",
        view,
        columns,
        "All",
        sort,
        {},
        listType == LIST_TYPES.INFRA_LIST,
        false,
        false,
        listType == LIST_TYPES.VENUE_LIST,
        listType == LIST_TYPES.MSP_LIST
      ]
    );
    // setSubListLoading(true);
    run()
      .then((response) => {
        // setSubListError(false);
        subCount.current += response.data.length;
        if (offset == 0) {
          setSubList([...listFormatter(response.data, permissions, venuePermissions)]);
        } else {
          setSubList((prevState) => [...prevState, ...listFormatter(response.data, permissions, venuePermissions)]);
        }

        if (response.data.length == 20) getRowList({ ...data, offset: subCount.current });
        else setHasMore(false);
      })
      .catch((err) => {
        let x = new CatchedWebError(err);
        // setSubListError(x.message);
      })
      .finally(() => {
        // setSubListLoading(false);
      });

    return controller;
  }
};

const ExpRow = (props) => {
  const {
    level,
    leafNode,
    item,
    notExpandable,
    parentIds,
  } = props;

  const {
    cols,
    sort,
    disableCheck,
    filterActive,
    isSelector,
    mainFilter,
    selections,
    setSelections,
    search,
    venueSelection,
    setVenueSelection,
    treeStruct,
    subListClose,
    deletingVenue,
    disableCollapseRoot,
    getTreeStruct,
    setIsSelector,
    enableOrgSelection,
    listView,
    listType
  } = useContext(OrgListContext);

  const permissions = useSelector((store) => store?.rbac?.permissions);
  const venuePermissions = useSelector(
    (store) => store?.rbac?.venuePermissions
  );
  const view = useSelector((store) => store.identity.meta.view);
  const [expand, setExpand] = useState(item?.children?.length > 0);
  const [hideExpand, setHideExpand] = useState(false);
  const [subList, setSubList] = useState(item?.children ?? []);
  const [hasMore, setHasMore] = useState(true);
  const [firstCell, setFirstCell] = useState(props.children[0] ?? props.children[1])

  const checkbox = useRef(null);
  const subCount = useRef(0);
  const getRowListParams = useCallback((newData) => {
    return {
      offset: 0,
      cols: cols,
      listType: listType,
      item: item,
      view: view,
      sort: sort,
      subCount: subCount,
      setSubList: setSubList,
      permissions: permissions,
      venuePermissions: venuePermissions,
      setHasMore: setHasMore,
      mainFilter: mainFilter,
      ...newData
    }
  }, [cols, item, listType, mainFilter, permissions, sort, subCount, venuePermissions, view])

  useEffect(() => {
    setFirstCell(props.children[0] ?? props.children[1])
  }, [props.children])


  useEffect(() => {
    if (subListClose) {
      if (disableCollapseRoot && level != 1) setExpand(false);
      if (subList.length == 1 && subList[0]?.venueId == deletingVenue)
        setHideExpand(true);
      setSubList(prevState => [...prevState.filter(it => it.venueId != deletingVenue)])
    }
  }, [subListClose, deletingVenue]);

  useEffect(() => {
    if (item.children == null || item.children?.length == 0) {
      if (expand) {
        let controller = getRowList(getRowListParams({ offset: 0 }));

        return () => {
          controller?.abort();
        };
      } else {
        setSubList([]);
        setHasMore(true);
        subCount.current = 0;
      }
    }
    else setHasMore(false)
  }, [expand, props.orgId, item.venueId]);

  useEffect(() => {
    if (isSelector && checkbox.current) {
      //if org
      if (item.venueId == null && item.infraItemId == null && venueSelection.applyToOrg == item.orgId) {
        //check if state should be partial
        let venueCounts = {
          notSelected: 0,
          partial: 0,
          selected: 0
        }
        for (const venue in (selections?.venues ?? {})) {
          if (selections.venues[venue] == 0) {
            venueCounts.notSelected = venueCounts.notSelected + 1;
          }
          else if (selections.venues[venue] == 1) {
            checkbox.current.indeterminate = true;
            checkbox.current.checked = false;
            break;
          }
          else if (selections.venues[venue] == 2) {
            venueCounts.selected = venueCounts.selected + 1;
          }
        }
        if (venueCounts.partial === 0) {
          let noOfVenues = Object.keys(treeStruct?.venue ?? {}).length
          if (noOfVenues === venueCounts.notSelected) {
            checkbox.current.indeterminate = false;
            checkbox.current.checked = false;
          }
          else if (noOfVenues === venueCounts.selected) {
            checkbox.current.indeterminate = false;
            checkbox.current.checked = true;
          }
          else if (venueCounts.notSelected !== 0 || venueCounts.selected !== 0) {
            checkbox.current.indeterminate = true;
            checkbox.current.checked = false;
          }
        }
      }
      else {
        //Checking if change in their state
        const infraIds = new Set(treeStruct?.venue[item.venueId]?.infrastructure)
        const childIds = new Set(treeStruct?.venue[item.venueId]?.children)
        if (item.infraItemId == null) {
          //If row is a venue
          if (selections.venues[item.venueId] == 2) {
            checkbox.current.checked = true;
            checkbox.current.indeterminate = false;
          } else if (selections.venues[item.venueId] == 1) {
            if (
              expand &&
              search.length == 0 &&
              !hasMore &&
              Array.from(childIds).every((it) => selections.venues[it] == 2) &&
              Array.from(infraIds).every((it) => selections.infras[it] == 2)
            ) {
              //check if it should move from partial to full
              setSelections((prevState) => {
                return {
                  ...prevState,
                  venues: { ...prevState.venues, [item.venueId]: 2 },
                };
              });
              setVenueSelection((prevState) => {
                let sel = { ...prevState };
                sel.venues.add(item.venueId);
                sel.venueDelete.delete(item.venueId);

                treeStruct.venue[item.venueId].infrastructure.map((infra) => {
                  sel.infras.add(infra);
                  sel.infraDelete.delete(infra);
                });
                return { ...sel };
              });
            } else if (
              expand &&
              search.length == 0 &&
              !hasMore &&
              Array.from(childIds).every((it) => selections.venues[it] == 0) &&
              Array.from(infraIds).every((it) => selections.infras[it] == 0)
            ) {
              setSelections((prevState) => {
                return {
                  ...prevState,
                  venues: { ...prevState.venues, [item.venueId]: 0 },
                };
              });
            }
            checkbox.current.indeterminate = true;
            checkbox.current.checked = false;
          } else {
            checkbox.current.indeterminate = false;
            checkbox.current.checked = false;
          }
        } else {
          if (selections.infras[item.infraItemId] == 2) {
            checkbox.current.checked = true;
            checkbox.current.indeterminate = false;
          } else {
            checkbox.current.checked = false;
            checkbox.current.indeterminate = false;
          }
        }
      }
    }
  }, [selections, hasMore]);

  const rippleVal = useCallback((venueId, val, treeStructure = treeStruct) => {
    setSelections((prevState) => {
      let sel = { ...prevState };
      treeStructure.venue[venueId].children.forEach((it) => {
        sel.venues[it] = val;
      });
      treeStructure.venue[venueId].infrastructure.forEach((it) => {
        sel.infras[it] = val;
      });
      return { ...sel };
    });

    if (val == 0) {
      setVenueSelection((prevState) => {
        let sel = { ...prevState };
        treeStructure.venue[venueId].children.forEach((it) => {
          // if(sel.venues.has(it))
          sel.venueDelete.add(it);
          sel.venues.delete(it);
        });
        treeStructure.venue[venueId].infrastructure.forEach((it) => {
          // if(profileScrn){
          sel.infraDelete.add(it);
          sel.infras.delete(it)
          // }
          // else {
          //   sel.infras.delete(it);
          //   sel.infraDelete.add(it);
          // }
        });
        if (sel.venues.size == 0 && sel.infras.size == 0)
          sel.applyToOrg = null
        return { ...sel };
      });
    } else {
      setVenueSelection((prevState) => {
        let sel = { ...prevState };
        treeStructure.venue[venueId].children.forEach((it) => {
          sel.venues.add(it);
          sel.venueDelete.delete(it);
        });
        treeStructure.venue[venueId].infrastructure.forEach((it) => {
          // if(profileScrn){
          sel.infras.add(it);
          sel.infraDelete.delete(it);
          // }
          // else{
          //   sel.infraDelete.delete(it);
          //   sel.infras.delete(it);
          // }
        });
        sel.applyToOrg = item.orgId
        return { ...sel };
      });
    }

    treeStructure.venue[venueId].children.forEach((it) => {
      rippleVal(it, val, treeStructure);
    });
  }, [item.orgId, setSelections, setVenueSelection, treeStruct]);

  //Handle check
  const handleCheck = useCallback((id, type, infraId, orgId, treeStructure = treeStruct) => {
    if (type == 'org') {
      //current state:
      //none
      if (venueSelection?.venues?.size == 0 && venueSelection?.infras?.size == 0) {
        setVenueSelection(prevState => {
          let newVenuesSet = new Set();
          for (const venueId in (treeStructure.venue ?? {})) {
            newVenuesSet.add(Number(venueId))
          }
          let newInfrasSet = new Set();
          for (const infraId in (treeStructure.infrastructure ?? {})) {
            newInfrasSet.add(Number(infraId))
          }
          return {
            venues: newVenuesSet,
            venueDelete: new Set(),
            infras: newInfrasSet,
            infraDelete: new Set(),
            applyToOrg: orgId
          }
        })
        setSelections(prevState => {
          let newVenues = {};
          for (const venueId in treeStructure.venue ?? {}) {
            newVenues[venueId] = 2;
          }
          let newInfras = {};
          for (const infraId in (treeStructure.infrastructure ?? {})) {
            newInfras[infraId] = 2;
          }
          return {
            venues: newVenues,
            infras: newInfras
          }
        })
      }
      //partial or full
      else {
        setVenueSelection(prevState => {
          return {
            venues: new Set(),
            venueDelete: new Set(),
            infras: new Set(),
            infraDelete: new Set(),
            applyToOrg: null
          }
        })
        setSelections({ infras: {}, venues: {} })
      }
    }
    else if (type == "venue") {
      setExpand(true);
      //If row is a venue
      if (selections.venues[id] == 2) {
        //If the venue was selected
        setSelections((prevState) => {
          return { ...prevState, venues: { ...prevState.venues, [id]: 0 } };
        });
        setVenueSelection((prevState) => {
          let sel = { ...prevState };
          // if(sel.venues.has(id))
          sel.venueDelete.add(id);
          sel.venues.delete(id);
          if (sel.venues.size == 0 && sel.infras.size == 0)
            sel.applyToOrg = null
          return { ...sel };
        });
        if (parentIds.length > 0) {
          //Move parent to (partial) state
          parentIds.map((parent) => {
            if (selections.venues[parent] != 1)
              setSelections((prevState) => {
                return {
                  ...prevState,
                  venues: { ...prevState.venues, [parent]: 1 },
                };
              });
            //Remove parent and add infra
            setVenueSelection((prevState) => {
              let sel = { ...prevState };
              // if(sel.venues.has(parent))
              sel.venueDelete.add(parent);
              sel.venues.delete(parent);

              treeStructure.venue[parent]?.infrastructure.forEach((infra) => {
                if (selections.infras[infra] == 2) {
                  sel.infras.add(infra);
                  sel.infraDelete.delete(infra);
                }
              });
              return { ...sel };
            });
          });
        }
        //unselect the children
        //Unselect infras
        rippleVal(id, 0, treeStructure);
      } else if (selections.venues[id] == 1) {
        //If the venue was partially selected

        //Unselect the venue
        setSelections((prevState) => {
          return { ...prevState, venues: { ...prevState.venues, [id]: 0 } };
        });
        setVenueSelection((prevState) => {
          let sel = { ...prevState };
          // if(sel.venues.has(id))
          sel.venueDelete.add(id);
          sel.venues.delete(id);
          if (sel.venues.size == 0 && sel.infras.size == 0)
            sel.applyToOrg = null
          return { ...sel };
        });
        //Unselect all children
        //Unselect Infras
        rippleVal(id, 0, treeStructure);
      } else {
        //If the venue was unselected -- select it
        setSelections((prevState) => {
          return { ...prevState, venues: { ...prevState.venues, [id]: 2 } };
        });
        if (parentIds.length > 0) {
          //Move parent to (partial) state
          parentIds.map((parent) => {
            if (selections.venues[parent] != 1)
              setSelections((prevState) => {
                return {
                  ...prevState,
                  venues: { ...prevState.venues, [parent]: 1 },
                };
              });
            //Remove parent and add infra
            setVenueSelection((prevState) => {
              let sel = { ...prevState };
              // if(sel.venues.has(parent))
              sel.venueDelete.add(parent);
              sel.venues.delete(parent);

              treeStructure.venue[parent]?.infrastructure.forEach((infra) => {
                if (selections.infras[infra] == 2) {
                  sel.infras.add(infra);
                  sel.infraDelete.delete(infra);
                }
              });
              return { ...sel };
            });
          });
        }
        setVenueSelection((prevState) => {
          let sel = { ...prevState };
          sel.venues.add(id);
          sel.venueDelete.delete(id);
          sel.applyToOrg = item.orgId
          return { ...sel };
        });
        //Select all its children
        //Select all infras
        rippleVal(id, 2, treeStructure);
      }
    } else {
      if (selections.infras[infraId] == 2) {
        //Make its parent partial
        if (parentIds.length > 0) {
          //Move parent to (partial) state
          parentIds.map((parent) => {
            if (selections.venues[parent] != 1)
              setSelections((prevState) => {
                return {
                  ...prevState,
                  venues: { ...prevState.venues, [parent]: 1 },
                };
              });
            //Remove parent and add infra
            setVenueSelection((prevState) => {
              let sel = { ...prevState };
              // if(sel.venues.has(parent))
              sel.venueDelete.add(parent);
              sel.venues.delete(parent);

              treeStructure.venue[parent]?.infrastructure.forEach((infra) => {
                if (selections.infras[infra] == 2 && infra != infraId) {
                  sel.infras.add(infra);
                  sel.infraDelete.delete(infra);
                }
              });
              sel.applyToOrg = item.orgId
              return { ...sel };
            });
          });
        }

        //Unselect the infra
        setSelections((prevState) => {
          return {
            ...prevState,
            infras: { ...prevState.infras, [infraId]: 0 },
          };
        });

        setVenueSelection((prevState) => {
          let sel = { ...prevState };
          if (sel.venues.has(id)) {
            sel.venueDelete.add(id);
            sel.venues.delete(id);
            treeStructure.venue[id].infrastructure.forEach((it) => {
              if (it != infraId) sel.infras.add(it);
            });
          } else {
            sel.infraDelete.add(infraId);
            sel.infras.delete(infraId);
          }
          if (sel.venues.size == 0 && sel.infras.size == 0)
            sel.applyToOrg = null
          return { ...sel };
        });
      } else {
        if (parentIds.length > 0) {
          //Move parent to (partial) state
          parentIds.map((parent) => {
            if (selections.venues[parent] != 1)
              setSelections((prevState) => {
                return {
                  ...prevState,
                  venues: { ...prevState.venues, [parent]: 1 },
                };
              });
            //Remove parent and add infra
            setVenueSelection((prevState) => {
              let sel = { ...prevState };
              // if(sel.venues.has(parent))
              sel.venueDelete.add(parent);
              sel.venues.delete(parent);

              treeStructure.venue[parent]?.infrastructure.forEach((infra) => {
                if (selections.infras[infra] == 2 && infra != infraId) {
                  sel.infras.add(infra);
                  sel.infraDelete.delete(infra);
                }
              });

              return { ...sel };
            });
          });
        }

        setSelections((prevState) => {
          return {
            ...prevState,
            infras: { ...prevState.infras, [infraId]: 2 },
          };
        });

        setVenueSelection((prevState) => {
          let sel = { ...prevState };
          sel.infras.add(infraId);
          sel.infraDelete.delete(infraId);
          sel.applyToOrg = item.orgId;

          return { ...sel };
        });
      }
    }
  }, [item.orgId, parentIds, rippleVal, selections, setSelections, setVenueSelection, treeStruct, venueSelection]);

  return (
    <>
      <div className={"ExpRow " + props.className}>
        <ExpCell
          {...firstCell.props}
          expand={expand}
          level={level}
          hasExpandIcon={!leafNode}
        >
          <div className="d-flex align-items-center">
            <div className="d-flex align-items-center justify-content-start position-relative">
              {props.expand && expand && (
                <div
                  className={`${firstCell.props.last || firstCell.props.level == undefined
                    ? "fixed-line"
                    : "fixed-line-inner"
                    }`}
                />
              )}
              {notExpandable ? null : !leafNode && !hideExpand ? (
                expand ? (
                  <CollapseIcon
                    className='collapse-icon'
                    width={14}
                    height={14}
                    onClick={() => setExpand(!expand)}
                  />
                ) : (
                  <ExpandIcon
                    className='expand-icon'
                    width={14}
                    height={14}
                    onClick={() => setExpand(!expand)}
                  />
                )
              ) : (
                <div className="empty-space mr-1">
                  &nbsp;
                </div>
              )}
              {isSelector &&
                (item.venueId != null || item.infraItemId != null || (enableOrgSelection && item.orgTypeId == BUSINESS)) &&
                (item.infraItemId != null ||
                  (item?.highlighted == null
                    ? search.length == 0 && mainFilter == "All" && !filterActive
                    : item.highlighted)) && (
                  <input
                    className={`${disableCheck || item.restricted || (venueSelection.applyToOrg != null && venueSelection.applyToOrg != item.orgId) ? 'cursor-not-allowed' : 'cursor-pointer'} mr-50 ${item.infraItemId != null ? 'ml-50' : ''}`}
                    type="checkbox"
                    ref={checkbox}
                    disabled={disableCheck || item.restricted || (venueSelection.applyToOrg != null && venueSelection.applyToOrg != item.orgId)}
                    onClick={() => {
                      if (
                        venueSelection.applyToOrg != item.orgId
                      ) {
                        getTreeStruct(item.orgId)
                          .then((treeStructure) => {
                            handleCheck(
                              item.venueId,
                              item.infraItemId != null ? (
                                "infra"
                              ) : item.venueId != null ? (
                                "venue"
                              ) : (
                                "org"
                              ),
                              item.infraItemId,
                              item.orgId,
                              treeStructure
                            )
                          })
                          .catch(err => {
                            setIsSelector(false)
                            make_custom_toast('error', 'Bulk Edit', (new CatchedWebError(err)).message)
                          })
                      }
                      else {
                        handleCheck(
                          item.venueId,
                          item.infraItemId != null ? (
                            "infra"
                          ) : item.venueId != null ? (
                            "venue"
                          ) : (
                            "org"
                          ),
                          item.infraItemId,
                          item.orgId
                        )
                      }
                    }
                    }
                  />
                )}
              {firstCell.props.children}
            </div>
          </div>
        </ExpCell>
        {props.children[0] == null
          ? props.children.slice(2)
          : props.children.slice(1)}
      </div>
      {expand && (
        <>
          <SubList
            {...props}
            list={subList}
            setList={setSubList}
            level={level + 1}
            notExpandable={listView === "flat" ? true : false}
            parentIds={[...(parentIds ?? []), ...(item.venueId ? [item.venueId] : [])]}
          />
        </>
      )}
    </>
  );
};

export const EllipsedText = (props) => {
  const { text } = props;
  const [isHovered, setIsHovered] = useState(false)

  return (
    <div className="d-flex align-items-center"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="ellipsed-text long-text-div">
        {text}
      </div>
      <Suspense fallback={<></>}>
        <Copy
          className={`cursor-pointer ${isHovered ? '' : 'invisible'}`}
          height={18}
          onClick={() => handleCopy(text)}
        />
      </Suspense>
    </div>
  )
}

const SubList = memo((props) => {
  const { list, setList, level, notExpandable, isRoot } =
    props;
  const {
    cols,
    search,
    hideAlarmIcons,
    showVenueOptions,
    showOrgOptions,
    setDeleteItem,
    setDowngrade,
    setOptions,
    mainFilter,
    removeBlueHighlight,
    filterActive,
    setDeleteModal,
    setDeletingVenue,
    setIsDeleteInfraOpen,
    onlyOrgFilterApplied,
    venueSelection,
    listView,
    listType
  } = useContext(OrgListContext);
  const [enableDowngrade, setEnableDowngrade] = useState(true);

  const rebooting = useSelector((store) => store?.rebooting?.data);
  const switchOrgs = useSelector((store) => store.rbac.data.orgs)?.map(
    (it) => it.orgId
  );
  const permissions = useSelector((store) => store.rbac?.permissions);
  const activeOrg = useSelector((store) => store.activeOrg.data);
  const view = useSelector((store) => store.identity.meta.view);
  const venuePermissions = useSelector(
    (store) => store?.rbac?.venuePermissions
  );
  const infraType = useSelector((store) => store.infraTypes.data);
  const socketMessage = useSelector(store => store.identity.meta.updateDashboardMessage);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (socketMessage != null) {
      const updateRelease = (arr) => {
        return arr?.map((item) => {
          if (item.infraItemId != null && item.infraItemId == socketMessage?.infraItemId) {
            let socketCopy = socketMessage;
            dispatch(identityActions.clearUpdateDashboardMessage())
            return { ...item, firmwareStatus: socketCopy.currentStatus, firmwareVersion: socketCopy.currentVersion };
          }
          // else if(item?.children?.length > 0)
          //   return {...item, children: updateRelease(item.children)}
          else
            return item
        })
      }
      setList((prevState) => {
        return updateRelease(prevState)
      })
    }
  }, [socketMessage])


  const checkAllTask = (item) => {
    if (item.validated && item.photos && item.initialized && item.installed)
      return true
    return false
  }

  return (
    <Suspense fallback={<></>}>
      {list.map((item, index) => {
        return (
          <ExpRow
            key={index}
            className={
              (!removeBlueHighlight && isRoot && listView === "tree" ? "bg-color--light-blue " : "") +
              (((
                item.highlighted != null
                  ? !item.highlighted
                  : (search.length > 0 || mainFilter != "All" || filterActive) && listView != "flat"
              ) && !onlyOrgFilterApplied) ||
                (venueSelection?.applyToOrg != null && venueSelection?.applyToOrg != item.orgId)
                ? "opacity-50 "
                : "")
            }
            leafNode={item.leafNode}
            orgId={item.orgId}
            itemId={item.itemId}
            level={level}
            item={item}
            parentLast={
              props.parentLast
                ? [...props.parentLast, index == list.length - 1]
                : [index == list.length - 1]
            }
            notExpandable={notExpandable || item.infraItemId != null}
            parentIds={props.parentIds ?? []}
          >
            {cols.Status && listView === "flat" ? (
              <ExpCell
                level={level}
                width="100px"
                showLines={!notExpandable && level > 1}
                last={index == list?.length - 1}
                parentLast={
                  props?.parentLast
                    ? [...props?.parentLast, index == list?.length - 1]
                    : []
                }
              >
                {!!rebooting[item.infraItemId] ? (
                  <Suspense fallback={<></>}>
                    <TimeProgress
                      onList
                      start={rebooting[item.infraItemId]?.start}
                      end={rebooting[item.infraItemId]?.end}
                      infraId={item.infraItemId}
                    />
                  </Suspense>
                ) : (
                  <Suspense fallback={<></>}>
                    <LightBadge color={statusColor[item.status]}>
                      <div className="text-dark">{statusText[item.status]}</div>
                    </LightBadge>
                  </Suspense>
                )}
              </ExpCell>
            ) : null}
            <ExpCell
              showLines={
                !(cols.Status && listView === "flat") &&
                !notExpandable &&
                level > 1
              }
              width="350px"
              last={index == list.length - 1}
              parentLast={
                props.parentLast
                  ? [...props.parentLast, index == list.length - 1]
                  : []
              }
            >
              {item.infraName != null ? (
                <span
                  className="d-flex align-items-center"
                  onClick={() => {
                    if (item.orgId == activeOrg.orgId) {
                      if (
                        venuePermissions[item.venueId] == null
                          ? permissions?.manageInfra?.view
                          : venuePermissions[item.venueId]?.infra?.view
                      )
                        navigate(
                          `/organization/${item.orgId}/infra/${item.infraItemId}/`
                        );
                    } else if (permissions?.manageHierarchy?.view)
                      navigate(
                        `/organization/${item.orgId}/infra/${item.infraItemId}/`
                      );
                  }}
                >
                  {/* {item.infraCategory === 1 ? (
                    <>
                      <Suspense fallback={<></>}>
                        <APSVG width="24" height="24"  className='mr-50'/>
                      </Suspense>
                    </>
                  ) : (
                    <>
                      <Suspense fallback={<></>}>
                        <SwitchSVG width="24" height="24"  className='mr-50'/>
                      </Suspense>
                    </>
                  )} */}
                  <div className="d-flex flex-column">
                    <div
                      className={
                        (
                          item.orgId == activeOrg.orgId
                            ? permissions?.manageVenue?.view
                            : permissions?.manageHierarchy?.view
                        )
                          ? "table-link"
                          : ""
                      }
                    >
                      <div className="d-flex align-items-center">
                        {item?.infraCategory === 1 ? (
                          <>
                            <Suspense fallback={<></>}>
                              <APSVG width="24" height="24" />
                            </Suspense>
                          </>
                        ) : (
                          <>
                            <Suspense fallback={<></>}>
                              <SwitchSVG width="24" height="24" />
                            </Suspense>
                          </>
                        )}
                        <div className="table-link ml-50"><Highlight search={search}>{item.infraName}</Highlight></div>
                      </div>
                    </div>
                    <div
                      className="d-flex align-items-center font--point-75rem"
                    >
                      <div className="d-flex align-items-center">
                        {item.type}
                      </div>
                      <div className="d-flex align-items-center">
                        {" "}
                        <div
                          className="small-black-dot"
                        ></div>
                        {
                          infraType.find(
                            (it) => it.infraTypeId == item.infraTypeId
                          )?.infraType
                        }
                      </div>
                    </div>
                  </div>
                </span>
              ) : item.orgName ? (
                <div
                  className="d-flex py-50"
                  onClick={() => {
                    if (
                      item.orgId == activeOrg.orgId &&
                      !item.restricted
                    ) {
                      navigate(`/organization/${item.orgId}`);
                    } else if (
                      !item.restricted &&
                      permissions?.manageHierarchy?.view
                    )
                      navigate(`/organization/${item.orgId}/`);
                  }}
                >
                  {
                    <div className="d-flex align-items-center ">
                      {hideAlarmIcons || listType == LIST_TYPES.VENUE_LIST || (listType == LIST_TYPES.ORG_LIST && view == BUSINESS_VIEW)
                        ? null
                        : statusIcons(item.zone)}
                      <span className="mr-50">
                        {item.orgTypeId == BUSINESS ? <OrgIcon width={22} height={22} />
                          : <MSPIcon width={22} height={22} />}
                      </span>
                    </div>
                  }
                  <div>
                    <div className="d-flex">
                      <div
                        className={`${(item.orgId == activeOrg.orgId ||
                          permissions?.manageHierarchy?.view) &&
                          !item.restricted
                          ? "table-link"
                          : ""
                          } d-flex align-items-center`}
                      >
                        <Highlight search={search}>{item.orgName}</Highlight>
                      </div>
                      {listType == LIST_TYPES.INFRA_LIST ?
                        <span className="online-infra-counter ml-50">
                          <LeftBracket />
                          <span
                            className={`online-infra-count normal-size text-${item?.onlineInfras > 0 ? "success font-weight--600" : "dark"
                              }`}
                          >
                            {item?.onlineInfras}
                          </span>
                          <span className="normal-size">/</span>{item?.totalInfras}
                          <RightBracket />
                        </span>
                        : null}
                    </div>
                    {view == BUSINESS_VIEW ?
                      <div>{item.notes}</div>
                      : RowSecondLine(item)
                    }
                  </div>
                </div>
              ) : item.venueName ? (
                <div
                  className="d-flex py-50"
                  onClick={() => {
                    if (item.orgId == activeOrg.orgId && !item.restricted) {
                      if (permissions?.manageVenue?.view)
                        navigate(
                          `/organization/${item.orgId}/venues/${item.venueId}`
                        );
                    } else if (
                      !item.restricted &&
                      permissions?.manageHierarchy?.view
                    )
                      navigate(
                        `/organization/${item.orgId}/venues/${item.venueId}`
                      );
                  }}
                >
                  {
                    <div className="d-flex align-items-center ">
                      {(hideAlarmIcons || (listType == LIST_TYPES.ORG_LIST && view == BUSINESS_VIEW)) ? null : statusIcons(item.zone)}
                      <span>{VenueIcon[item.venueType]}</span>
                    </div>
                  }
                  <div className="d-flex align-items-center">
                    <div>
                      <div className="d-flex">
                        <div
                          className={`${(item.orgId == activeOrg.orgId
                            ? permissions?.manageVenue?.view
                            : permissions?.manageHierarchy?.view) &&
                            !item.restricted
                            ? "table-link"
                            : ""
                            } d-flex align-items-center`}
                        >
                          <Highlight search={search}>
                            {item.venueName}
                          </Highlight>
                        </div>
                        {listType == LIST_TYPES.INFRA_LIST ?
                          <span className="online-infra-counter ml-50">
                            <LeftBracket />
                            <span
                              className={`online-infra-count normal-size text-${item?.onlineInfras > 0 ? "success font-weight--600" : "dark"
                                }`}
                            >
                              {item?.onlineInfras}
                            </span>
                            <span className="normal-size">/</span>{item?.totalInfras}
                            <RightBracket />
                          </span>
                          : null}
                      </div>
                      {item.restricted || view == BUSINESS_VIEW ? null : (
                        view == DEPLOYMENT_VIEW ? listType != LIST_TYPES.INFRA_LIST ? null : (
                          <div
                            className="second-line font-weight-bold"
                          >
                            {(item?.totalTasks ?? 0) > 0 ?
                              <PendingTasksSubHeading
                                pendingTasks={item?.pendingTasks}
                                totalTasks={item?.totalTasks}
                              /> : "No Tasks"}
                          </div>
                        ) : (
                          <div
                            className="second-line font-weight-bold"
                          >
                            {RowSecondLine(item)}
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              ) : null}
            </ExpCell>
            {listType == LIST_TYPES.INFRA_LIST &&
              listView === 'tree' &&
              <ExpCell hide={item.restricted} level={level} width="60px">
                {item.infraItemId == null && item.venueId != null ? (
                  <div>
                    <Suspense fallback={<></>}>
                      <OperationCounters
                        id={item.venueId}
                        zone={item.zone}
                        alarmsData={{ show: (item.optAlarmCount ?? 0) > 0, total: item.optAlarmCount }}
                        ticketsData={{ show: (item.optTicketCount ?? 0) > 0, total: item.optTicketCount }}
                        fwScheduleData={
                          {
                            show: (item.optScheduleCount ?? 0) > 0,
                            total: item.optScheduleCount,
                            venueScheduleId: item.scheduleId,
                            venueSnoozedDate: item.scheduleSnoozed,
                            venueScheduleDate: item.scheduleDate,
                            venueTimezone: item.venueTimezone,
                            subVenueSchedulesCount: item.subVenueSchedulesCount,
                            subVenueSnoozedDate: item.subVenueSnoozedDate,
                            subVenueScheduleTimezone: item.subVenueScheduleTimezone
                          }
                        }
                        venueId={item.venueId}
                        venueName={item.venueName}
                        venueType={item.venueType}
                        enableFwClick={permissions?.manageFirmware?.update &&
                          item?.scheduleId != null &&
                          item?.venueId != null &&
                          item?.scheduleDate != null &&
                          item?.scheduleSnoozed != null &&
                          item?.venueTimezone != null
                        }
                        fwClick={() => {
                          setOptions({
                            scheduleId: item?.scheduleId,
                            venueId: item?.venueId,
                            scheduleDate: item?.scheduleDate,
                            scheduleSnoozed: item?.scheduleSnoozed,
                            timezone: item?.venueTimezone,
                            setSubList: setList,
                          });
                        }}
                        setVenues={setList}
                        small={true}
                      />
                    </Suspense>
                  </div>
                )
                  : null}
              </ExpCell>}
            {cols.Alarms && (
              <ExpCell className="justify-content-end" level={level} width="150px">
                <div className="padding-right--24px">
                  <AlarmOrg
                    critical={item.criticalAlarms}
                    major={item.majorAlarms}
                    orgs={[item.orgId, ...item.criticalAlarmOrgs ?? [], ...item.majorAlarmOrgs ?? []]}
                    venues={!!item.venueId ? [item.venueId, ...item.criticalAlarmVenues ?? [], ...item.majorAlarmVenues ?? []] : []}
                  />
                </div>
              </ExpCell>
            )}
            {cols.State && (
              <ExpCell level={level} width="100px">
                {
                  listType == LIST_TYPES.ORG_LIST ?
                    (item.venueId ?
                      <></> :
                      OrgState(item.state, item.orgTypeId)) :
                    ((item.state != null && item.venueId != null) ?
                      <Suspense fallback={<></>}>
                        <VenueStateGroup active={item.state} />
                      </Suspense> :
                      <></>)
                }
              </ExpCell>
            )}
            {cols.Status && listView === "tree" ? (
              <ExpCell level={level} width="100px">
                {!!rebooting[item.infraItemId] ? (
                  <Suspense fallback={<></>}>
                    <TimeProgress
                      onList
                      start={rebooting[item.infraItemId]?.start}
                      end={rebooting[item.infraItemId]?.end}
                      infraId={item.infraItemId}
                    />
                  </Suspense>
                ) : (
                  <Suspense fallback={<></>}>
                    <LightBadge color={statusColor[item.status]}>
                      <div className="text-dark">{statusText[item.status]}</div>
                    </LightBadge>
                  </Suspense>
                )}
              </ExpCell>
            ) : null}
            {listType != LIST_TYPES.INFRA_LIST && cols.Operations ? (
              <ExpCell level={level} width="150px">
                {(item.venueId || item.orgId) ? (
                  <Suspense fallback={<></>}>
                    <OperationCounters
                      id={item.infraItemId ?? item.venueId ?? item.orgId}
                      zone={item.zone}
                      alarmsData={{
                        show: (item.optAlarmCount ?? 0) > 0,
                        total: item.optAlarmCount,
                      }}
                      ticketsData={{
                        show: (item.optTicketCount ?? 0) > 0,
                        total: item.optTicketCount,
                      }}
                      fwScheduleData={{
                        show: (item.optScheduleCount ?? 0) > 0,
                        total: item.optScheduleCount,
                        venueScheduleId: item.scheduleId,
                        venueSnoozedDate: item.scheduleSnoozed,
                        venueScheduleDate: item.scheduleDate,
                        venueTimezone: item.venueTimezone,
                        subVenueSchedulesCount: item.subVenueSchedulesCount,
                        subVenueSnoozedDate: item.subVenueSnoozedDate,
                        subVenueScheduleTimezone: item.subVenueScheduleTimezone,
                        infraScheduleSnoozed: item.scheduleSnoozed,
                      }}
                      isOrg={(item.venueId == null && listType == LIST_TYPES.ORG_LIST)}
                      // isInfra={item.infraItemId != null}
                      // infraName={item.infraName}
                      // infraItemId={item.infraItemId}
                      orgId={item.orgId}
                      venueId={item.venueId}
                      venueName={item.venueName}
                      venueType={item.venueType}
                      enableFwClick={
                        item.infraItemId != null ? (
                          item?.canReschedule &&
                          item.firmwareStatus == "Update-available"
                        ) : (
                          permissions?.manageFirmware?.update &&
                          item?.scheduleId != null &&
                          item?.venueId != null &&
                          item?.scheduleDate != null &&
                          item?.scheduleSnoozed != null &&
                          item?.venueTimezone != null
                        )
                      }
                      fwClick={() => {
                        if (item.infraItemId != null) {
                          setOptions({
                            scheduleId: item?.scheduleId,
                            venueId: item?.venueId,
                            infraItemId: item?.infraItemId,
                            scheduleDate: item?.scheduleDate,
                            scheduleSnoozed: item?.scheduleSnoozed,
                            timezone: item?.venueTimezone,
                            rescheduleInfra: true,
                            setSubList: setList
                          });
                        }
                        else {
                          setOptions({
                            scheduleId: item?.scheduleId,
                            venueId: item?.venueId,
                            scheduleDate: item?.scheduleDate,
                            scheduleSnoozed: item?.scheduleSnoozed,
                            timezone: item?.venueTimezone,
                            setSubList: setList,
                          });
                        }
                      }}
                      setInfras={setList}
                      setVenues={setList}
                    />
                  </Suspense>
                ) : null}
              </ExpCell>
            ) : null}
            {cols["Organization-Venue"] ? (
              <ExpCell level={level} width="250px">
                <div className="d-flex flex-column">
                  {item.orgName == null ? null : (
                    <div
                      className={
                        "d-flex align-items-center " +
                        (permissions?.manageHierarchy?.view ? "table-link" : "")
                      }
                      onClick={() => {
                        if (permissions?.manageHierarchy?.view)
                          navigate(`/organization/${item.orgId}`);
                      }}
                    >
                      <div className="mr-50">
                        {orgtypesIcons[item.orgTypeId]}
                      </div>
                      {item.orgName}
                    </div>
                  )}
                  {item.venueName == null ? null : (
                    <div
                      className={
                        "d-flex align-items-center " +
                        (permissions?.manageHierarchy?.view ? "table-link" : "")
                      }
                      onClick={() => {
                        if (permissions?.manageHierarchy?.view)
                          navigate(
                            `/organization/${item.orgId}/venues/${item.venueId}`
                          );
                      }}
                    >
                      <Building width="16" height="16" className="mr-50" />
                      {item.venueName}
                    </div>
                  )}
                </div>
              </ExpCell>
            ) : null}
            {cols["Tasks"] && (
              <ExpCell level={level}>
                {item.infraItemId && (
                  <div className={"d-flex align-items-center notes-div "}>
                    <div className={`d-flex pl-1 ${checkAllTask(item) ? "all-task-bg" : ""}`}  >
                      {
                        checkAllTask(item) &&
                        <Success className="all-task-done-check" width={16} height={16} />
                      }

                      <InstallTooltip
                        infraId={item.infraItemId}
                        placed={item.initialized}
                        firstConnectedAt={item.firstConnectedAt}
                        allTaskDone={checkAllTask(item)}

                      />
                      <ImageTooltip
                        hover={item.photos}
                        infraId={item.infraItemId}
                        showColor={item.photos}
                        allTaskDone={checkAllTask(item)}

                      />
                      <LayoutTooltip
                        infra={item}
                        venueId={item.venueId}
                        activeOrgId={activeOrg.orgId}
                        allTaskDone={checkAllTask(item)}
                      />
                      <ValidateTooltip
                        infraId={item.infraItemId}
                        speedTestResult={item.speedTestResult}
                        allTaskDone={checkAllTask(item)}
                      />
                    </div>
                  </div>
                )}
              </ExpCell>
            )}
            {cols["Room"] && (
              <ExpCell level={level}><div className="ellipsed-text long-text-div" title={item?.room ?? ""}>{!!item.room ? item.room : ""}</div></ExpCell>
            )}
            {cols["Location"] && (
              <ExpCell level={level}>
                <div className="d-flex align-items-center">
                  {item.infraItemId == null ? (
                    <div
                      className="flag-icon mr-50"
                      style={{
                        backgroundImage: `url( https://flagcdn.com/${item?.location?.toLowerCase()}.svg )`,
                      }}
                    >
                      &nbsp;
                    </div>
                  ) : null}
                  <div className="ellipsed-text long-text-div" title={item?.location ?? ""}><Highlight search={search}>{item?.location ?? ""}</Highlight></div>
                </div>
              </ExpCell>
            )}
            {cols["MAC Address"] ? (
              <ExpCell level={level} width="150px">
                {item?.macAddress ? MAC_ADDRESS(item.macAddress, true) : ""}
              </ExpCell>
            ) : null}
            {cols["Private IP"] ? (
              <ExpCell level={level} width="130px">
                {item.privateIP?.split("/")?.length > 0 ? ipFormater(item.privateIP?.split("/")[0] ?? "") : ""}
              </ExpCell>
            ) : null}
            {cols["Public IP"] ? (
              <ExpCell level={level} width="130px">
                {item.publicIP ?? ""}
              </ExpCell>
            ) : null}
            {cols["AP Profile"] ? (
              <ExpCell level={level} width="170px">
                <span
                  className="table-link"
                  onClick={() => {
                    navigate(
                      `/organization/${item.orgId}/profiles/${item.apProfileId}`
                    );
                  }}
                >
                  {item.apProfile ?? ""}
                </span>
              </ExpCell>
            ) : null}
            {cols["Switch Profile"] ? (
              <ExpCell level={level} width="170px">
                <span
                  className="table-link"
                  onClick={() => {
                    navigate(
                      `/organization/${item.orgId}/profiles/${item.switchProfileId}`
                    );
                  }}
                >
                  {item.switchProfile ?? ""}
                </span>
              </ExpCell>
            ) : null}
            {cols["RF Profile"] ? (
              <ExpCell level={level} width="170px">
                <span
                  className="table-link"
                  onClick={() => {
                    navigate(
                      `/organization/${item.orgId}/profiles/${item.rfProfileId}`
                    );
                  }}
                >
                  {item.rfProfile ?? ""}
                </span>
              </ExpCell>
            ) : null}
            {cols["Radio 2G"] ? (
              <ExpCell level={level} width="210px">
                {item.radio2G ? radioFormater(item.radio2G) : ""}
              </ExpCell>
            ) : null}
            {cols["Radio 5G"] && (
              <ExpCell level={level} width="210px">
                {item.radio5G ? radioFormater(item.radio5G) : ""}
              </ExpCell>
            )}
            {cols["Radio 6G"] && (
              <ExpCell level={level} width="210px">
                {item.radio6G ? radioFormater(item.radio6G) : ""}
              </ExpCell>
            )}

            {cols.Venues && <ExpCell className="justify-content-end" level={level} width="150px">
              <div className="d-flex padding-right--24px">
                <span>
                  <span className={`${((item?.onlineVenue ?? 0) > 0 && (item?.totalVenue ?? 0) > 0) ? 'text-success font-weight--600' : ''} font-size--larger`}>
                    {item.onlineVenue != null ? item.onlineVenue : "0"}
                  </span>
                  &nbsp;/&nbsp;
                  <span
                    className={`${((item?.totalVenue ?? 0) > 0 && item.venueId == null) ? 'table-link' : ''}`}
                    onClick={() => {
                      if ((item.totalVenue ?? 0) <= 0 || item.venueId != null) return;
                      navigate(`/organization/${item.orgId}/venues`)
                    }}
                  >
                    {item.totalVenue != null ? item.totalVenue : "0"}
                  </span>
                </span>
              </div></ExpCell>}
            {cols["Pending Tasks"] && (
              <ExpCell level={level} width="150px">
                {item.venueName && (
                  <div className="width--120px">
                    <PendingTaskBreakdown
                      id={item?.venueId}
                      pendingTask={item?.pendingTasks}
                      totalTask={item?.totalTasks}
                      venueId={item?.venueId}
                      venueName={item?.venueName}
                      venueType={item?.venueType}
                      leafNode={item?.leafNode}
                    />
                  </div>
                )}
              </ExpCell>
            )}
            {cols.Infrastructure && (
              <ExpCell
                level={level}
                width="150px"
                className="justify-content-end"
              >
                <div className="padding-right--24px">
                  {item.venueId != null ? (
                    <Suspense fallback={<></>}>
                      <InfraBreakdown
                        id={`infra${item.venueId}`}
                        venueId={item.venueId}
                        totalCount={item.totalInfras}
                        totalOnline={item.onlineInfras}
                        venueType={item.venueType}
                        venueName={item.venueName}
                        orgId={item.orgId}
                      />
                    </Suspense>
                  ) : (
                    (listType == LIST_TYPES.ORG_LIST || listType == LIST_TYPES.MSP_LIST) ?
                      <span>
                        <span
                          className={`${(item?.onlineInfras ?? 0) > 0 &&
                            (item?.totalInfras ?? 0) > 0
                            ? "text-success font-weight--600 cursor-pointer"
                            : ""
                            } font-size--larger`}
                          onClick={() => {
                            if (
                              (item?.onlineInfras ?? 0) > 0 &&
                              (item?.totalInfras ?? 0) > 0
                            ) {
                              navigate(`/organization/${item.orgId}/infra`, {
                                state: {
                                  filter: {
                                    status: ["ONLINE"],
                                  },
                                },
                              });
                            }
                          }}
                        >
                          {item?.onlineInfras != null ? item?.onlineInfras : "0"}
                        </span>
                        &nbsp;/&nbsp;
                        <span
                          className={`${(item?.totalInfras ?? 0) > 0 ? "table-link" : ""
                            }`}
                          onClick={() => {
                            if ((item?.totalInfras ?? 0) <= 0) return;
                            navigate(
                              `/organization/${item.orgId}/infra?filter=All`
                            );
                          }}
                        >
                          {item?.totalInfras != null ? item?.totalInfras : "0"}
                        </span>
                      </span> : <></>
                  )}
                </div>
              </ExpCell>
            )}
            {cols["Live Sites"] && <ExpCell className="justify-content-end" level={level} width="125px">
              {(item.orgName != null) ? <span className={`${item.liveSites > 0 ? "table-link cursor-pointer" : ""} padding-right--24px`} onClick={() => {
                if (item.liveSites > 0)
                  navigate(`/organization/${item.orgId}/venues`, { state: { filter: { state: [VENUE_STATE.LIVE] } } });
              }}
              >{item.liveSites}</span> : <></>}
            </ExpCell>
            }
            {cols["Proposed Amt"] && <ExpCell className="justify-content-end" level={level} width="150px">{<span className="pr-50">${item.proposedAmt}</span>}</ExpCell>}
            {cols["Proposed Subs"] && <ExpCell className="justify-content-end" level={level} width="170px">{<span className="pr-50">{item.proposedSubscription}</span>}</ExpCell>}
            {cols["In-Service"] && (
              <ExpCell level={level} width="150px">
                {item.inService != null ? (
                  <span>{UPTIME(item.inService, true, true, false)}</span>
                ) : (
                  ""
                )}
              </ExpCell>
            )}
            {cols.Cost && (
              <ExpCell level={level} width="150px">
                {item.cost != null ? `$${item.cost}` : ""}
              </ExpCell>
            )}
            {cols.Traffic ? (
              <ExpCell level={level} width="120px">
                {item.infraItemId == null ||
                  (item.infraItemId && item.status != "demo") ? (
                  <Suspense fallback={<></>}>
                    <ApexChartsTrendLine
                      color={["#B347E6", "#F5A742"]}
                      seriesTitles={["uplink", "downlink"]}
                      series={item?.trafficData}
                    />
                  </Suspense>
                ) : null}
              </ExpCell>
            ) : null}
            {cols.Clients ? (
              <ExpCell level={level} width="120px">
                {item.infraItemId == null ||
                  (item.infraItemId && item.status != "demo") ? (
                  <Suspense fallback={<></>}>
                    <ApexChartsTrendLine
                      color={["#5279CE"]}
                      seriesTitles={["client"]}
                      series={item?.clientData}
                    />
                  </Suspense>
                ) : null}
              </ExpCell>
            ) : null}
            {cols["UpTime"] && (
              <ExpCell level={level} hide={item.restricted} width="120px">
                <span id={`uptimeValue${item.infraItemId}`}>
                  {item.infraItemId
                    ? item.status == "connected"
                      ? UPTIME(item?.lastStatusAt, true, true, true)
                      : "-"
                    : ""}
                </span>
                <UncontrolledTooltip
                  target={`#uptimeValue${item.infraItemId}`}
                >
                  {item.infraItemId
                    ? item.status == "connected"
                      ? UPTIME(item?.lastStatusAt, false, true)
                      : "-"
                    : ""}
                </UncontrolledTooltip>
              </ExpCell>
            )}
            {cols.Release ? (
              item.infraItemId ? (
                <ExpCell level={level} width="100px">
                  <div
                    className="d-flex cursor-pointer"
                    id={`Firmware${item?.infraItemId}`}
                  >
                    <Suspense fallback={<></>}>
                      <LightBadge
                        color={versionColors[item.firmwareStatus] ?? "secondary"}
                      >
                        {item?.firmwareVersion?.replace("Unknown", ``) ?? "-"}
                      </LightBadge>
                    </Suspense>
                    {item?.firmwareStatus?.length > 0 && (
                      <UncontrolledTooltip
                        target={`Firmware${item?.infraItemId}`}
                      >
                        {firmwareChartKeys.indexOf(item?.firmwareStatus) != -1
                          ? firmwareChartLabels[
                          firmwareChartKeys.indexOf(item?.firmwareStatus)
                          ]
                          : ""}
                      </UncontrolledTooltip>
                    )}
                  </div>
                </ExpCell>
              ) : listType != LIST_TYPES.INFRA_LIST && item.venueId ? (
                <ExpCell level={level} width="100px">
                  <Suspense fallback={<></>}>
                    <FirmwarePopup
                      tooltipId={item.venueId}
                      data={item?.firmwareCount ?? {}}
                    >
                      <div className="d-flex cursor-pointer">
                        <Suspense fallback={<></>}>
                          <LightBadge
                            color={
                              versionColors[item?.firmwareStatus] ?? "secondary"
                            }
                          >
                            {item?.firmwareVersion?.replace("Unknown", ``) ?? ""}
                          </LightBadge>
                        </Suspense>
                      </div>
                    </FirmwarePopup>
                  </Suspense>
                </ExpCell>
              ) : (
                <ExpCell level={level}></ExpCell>
              )
            ) : null}
            {listType == LIST_TYPES.INFRA_LIST && cols.Operations ? (
              <ExpCell level={level} width="150px">
                {(item.infraItemId != null) ? (
                  <Suspense fallback={<></>}>
                    <OperationCounters
                      id={item.infraItemId ?? item.venueId ?? item.orgId}
                      zone={item.zone}
                      alarmsData={{
                        show: (item.optAlarmCount ?? 0) > 0,
                        total: item.optAlarmCount,
                      }}
                      ticketsData={{
                        show: (item.optTicketCount ?? 0) > 0,
                        total: item.optTicketCount,
                      }}
                      fwScheduleData={{
                        show: (item.optScheduleCount ?? 0) > 0,
                        total: item.optScheduleCount,
                        venueScheduleId: item.scheduleId,
                        venueSnoozedDate: item.scheduleSnoozed,
                        venueScheduleDate: item.scheduleDate,
                        venueTimezone: item.venueTimezone,
                        subVenueSchedulesCount: item.subVenueSchedulesCount,
                        subVenueSnoozedDate: item.subVenueSnoozedDate,
                        subVenueScheduleTimezone: item.subVenueScheduleTimezone,
                        infraScheduleSnoozed: item.scheduleSnoozed,
                      }}
                      isOrg={(item.venueId == null && listType == LIST_TYPES.ORG_LIST)}
                      isInfra={item.infraItemId != null}
                      infraName={item.infraName}
                      infraItemId={item.infraItemId}
                      orgId={item.orgId}
                      venueId={item.venueId}
                      venueName={item.venueName}
                      venueType={item.venueType}
                      enableFwClick={
                        item.infraItemId != null ? (
                          item?.canReschedule &&
                          item.firmwareStatus == "Update-available"
                        ) : (
                          permissions?.manageFirmware?.update &&
                          item?.scheduleId != null &&
                          item?.venueId != null &&
                          item?.scheduleDate != null &&
                          item?.scheduleSnoozed != null &&
                          item?.venueTimezone != null
                        )
                      }
                      fwClick={() => {
                        if (item.infraItemId != null) {
                          setOptions({
                            scheduleId: item?.scheduleId,
                            venueId: item?.venueId,
                            infraItemId: item?.infraItemId,
                            scheduleDate: item?.scheduleDate,
                            scheduleSnoozed: item?.scheduleSnoozed,
                            timezone: item?.venueTimezone,
                            rescheduleInfra: true,
                            setSubList: setList
                          });
                        }
                        else {
                          setOptions({
                            scheduleId: item?.scheduleId,
                            venueId: item?.venueId,
                            scheduleDate: item?.scheduleDate,
                            scheduleSnoozed: item?.scheduleSnoozed,
                            timezone: item?.venueTimezone,
                            setSubList: setList,
                          });
                        }
                      }}
                      setInfras={setList}
                      setVenues={setList}
                    />
                  </Suspense>
                ) : null}
              </ExpCell>
            ) : null}
            {cols["Serial Number"] ? (
              <ExpCell level={level} width="150px">
                {item?.serialNumber?.length > 0 ?
                  <EllipsedText
                    text={item.serialNumber}
                  /> : ''}
              </ExpCell>
            ) : null}
            {cols["Asset Tag"] ? (
              <ExpCell level={level} width="150px">
                {item?.assetTag?.length > 0 ?
                  <EllipsedText text={item?.assetTag} /> : ''}
              </ExpCell>
            ) : null}
            <ExpCell className="justify-content-end" level={level}>
              <UncontrolledDropdown
                className={
                  item.venueName == null || !showVenueOptions ? "d-none " : ""
                }
                direction="down"
                disabled={
                  !(
                    switchOrgs.includes(item.orgId) ||
                    permissions.manageVenue.update ||
                    permissions?.manageFirmware?.create
                  )
                }
              >
                <DropdownToggle color="white" className="w-0 p-0">
                  <span
                    className={
                      "material-symbols-outlined cursor-pointer " +
                      (!(
                        switchOrgs.includes(item.orgId) ||
                        permissions.manageVenue.update ||
                        permissions?.manageFirmware?.create
                      )
                        ? "text-secondary"
                        : "text-primary")
                    }
                    title="Open Dashboard"
                  >
                    more_vert
                  </span>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    className="w-100"
                    onClick={() => {
                      if (item.orgId == activeOrg.orgId) {
                        if (permissions?.manageVenue?.update)
                          navigate(
                            `/organization/${item.orgId}/venues/${item.venueId}/`
                          );
                      } else if (permissions?.manageHierarchy?.update)
                        navigate(
                          `/organization/${item.orgId}/venues/${item.venueId}/`
                        );
                    }}
                  >
                    Edit
                  </DropdownItem>
                  {permissions?.manageFirmware?.update &&
                    !!item?.firmwareVersion &&
                    item?.firmwareVersion !== item?.previousVersion &&
                    enableDowngrade && (
                      <DropdownItem
                        className="cursor-pointer w-100"
                        disabled={!item?.previousVersion}
                        onClick={() =>
                          setDowngrade({
                            venue: item,
                            downgrading: false,
                            onSuccess: () => setEnableDowngrade(false),
                          })
                        }
                      >
                        Revert Update
                      </DropdownItem>
                    )}
                  {permissions?.manageFirmware?.update &&
                    item.firmwareStatus == "Update-available" && (
                      <DropdownItem
                        className="w-100"
                        onClick={() => {
                          setOptions({
                            scheduleId: item?.scheduleId,
                            venueId: item?.venueId,
                            scheduleDate: item?.scheduleDate,
                            scheduleSnoozed: item?.scheduleSnoozed,
                            timezone: item?.venueTimezone,
                            setSubList: setList,
                          });
                        }}
                      >
                        Reschedule Update
                      </DropdownItem>
                    )}
                  {permissions?.manageVenue?.create && (
                    <DropdownItem
                      className="w-100"
                      onClick={() => {
                        navigate(
                          `/organization/${item.orgId}/venues/${item.venueId}/add/`
                        );
                      }}
                    >
                      Add Child
                    </DropdownItem>
                  )}
                  {!item.defaultVenue && permissions.manageVenue.delete && (
                    <DropdownItem
                      className="w-100"
                      onClick={() => {
                        setDeleteModal(item.venueId);
                        setDeletingVenue(item.venueId);
                      }}
                    >
                      Delete
                    </DropdownItem>
                  )}
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown
                className={
                  item.orgId == null || item.venueId != null || !showOrgOptions ? "d-none " : ""
                }
                direction="down"
                disabled={
                  !(
                    switchOrgs.includes(item.orgId) ||
                    permissions.manageOrganization.delete
                  )
                }
              >
                <DropdownToggle color="white" className="w-0 p-0">
                  <span
                    className={
                      "material-symbols-outlined cursor-pointer " +
                      (!(
                        switchOrgs.includes(item.orgId) ||
                        permissions.manageOrganization.delete
                      )
                        ? "text-secondary"
                        : "text-primary")
                    }
                    title="Open Dashboard"
                  >
                    more_vert
                  </span>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    className="w-100"
                    onClick={() => {
                      setDeleteItem(item);
                    }}
                  >
                    Delete
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              {item.infraItemId != null ? (
                <UncontrolledDropdown
                  direction="left"
                // disabled={!item?.dropdownEnabled}
                >
                  <DropdownToggle color="white" className="w-0 p-0">
                    <span
                      className="material-symbols-outlined text-primary cursor-pointer"
                      title="Actions"
                    >
                      more_vert
                    </span>
                  </DropdownToggle>
                  <DropdownMenu>
                    {item?.canEdit ? (
                      <DropdownItem
                        className="cursor-pointer w-100"
                        onClick={() => navigate(`${item.infraItemId}/settings`)}
                      >
                        Edit
                      </DropdownItem>
                    ) : null}
                    {item?.canReboot && item.status == "connected" && (
                      <DropdownItem
                        className="cursor-pointer w-100"
                        onClick={() => {
                          const { run } = createRequest(
                            services.infra.RUN,
                            [item.infraItemId],
                            { cmd: "reboot" }
                          );
                          run()
                            .then(() => {
                              make_custom_toast(
                                "success",
                                "Rebooting Infrastructure...",
                                "Your infrastructure will resume operation shortly."
                              );
                            })
                            .catch((err) => {
                              make_custom_toast(
                                "error",
                                "Unable to perform action.",
                                new CatchedWebError(err).message
                              );
                            });
                        }}
                      >
                        Reboot
                      </DropdownItem>
                    )}
                    {item?.canRetry && item.firmwareStatus == "Failed" && (
                      <DropdownItem
                        className="w-100 text-wrap"
                        onClick={() => {
                          let data = {
                            infras: [item.infraItemId],
                            scheduleId: parseInt(item.scheduleId),
                            org: true,
                          };
                          const { run } = createRequest(
                            services.fwupgrade.RETRY_FWUPGRADE,
                            [],
                            data
                          );

                          run()
                            .then((response) => {
                              make_custom_toast(
                                "success",
                                "Retrying Firmware Upgrade",
                                "Retrying..."
                              );
                            })
                            .catch((error) => {
                              make_custom_toast(
                                "error",
                                "Retrying Firmware Upgrade",
                                new CatchedWebError(error).message
                              );
                            });
                        }}
                      >
                        Retry Firmware Update
                      </DropdownItem>
                    )}
                    {item?.canReschedule &&
                      item.firmwareStatus == "Update-available" && (
                        <DropdownItem
                          onClick={() => {
                            setOptions({
                              scheduleId: item?.scheduleId,
                              venueId: item?.venueId,
                              infraItemId: item?.infraItemId,
                              scheduleDate: item?.scheduleDate,
                              scheduleSnoozed: item?.scheduleSnoozed,
                              timezone: item?.venueTimezone,
                              rescheduleInfra: true,
                            });
                          }}
                        >
                          Reschedule Update
                        </DropdownItem>
                      )}
                    {item?.canDelete && (
                      <DropdownItem
                        className="w-100"
                        onClick={() => {
                          setIsDeleteInfraOpen({
                            type: deleteTypes.INFRA,
                            infraId: item.infraItemId,
                            orgId: item.orgId
                          })
                        }}
                      >
                        Delete
                      </DropdownItem>
                    )}
                  </DropdownMenu>
                </UncontrolledDropdown>
              ) : null}
            </ExpCell>
          </ExpRow>
        );
      })}
    </Suspense>
  );
});

export const AlarmOrg = (props) => {
  const { critical, major, orgs = [], venues = [], displayNone = false } = props;
  const navigate = useNavigate();
  const venueId = venues.map(it => { return { venueId: it } })
  const activeOrgId = useSelector(store => store.activeOrg.data.orgId);
  const permissions = useSelector(store => store?.rbac?.permissions);

  return (
    <div className="d-flex align-items-center width--120px">
      <div className="w-50">
        {critical > 0 && (
          <div className="d-flex align-items-center justify-content-end table-link" onClick={() => {
            // console.log("he--->",orgs, venues)
            if (permissions?.manageNotificationstats?.view)
              navigate(`/organization/${activeOrgId}/notification`, {
                state: {
                  filter: {
                    venues: venueId,
                    orgs: orgs.map(it => { return { orgId: it } }),
                    // venueChildren: false, 
                    alertImpact: [ALERT_HIG],
                    alertType: ['alarm']
                  }
                }
              })
          }}>
            <Critical className="mr-0" width={18} height={18} />
            <div className="w-25 text-right">
              {critical}
            </div>
          </div>
        )}
      </div>
      <div className="w-50">
        {major > 0 && (
          <div className="d-flex align-items-center justify-content-end table-link" onClick={() => {
            if (permissions?.manageNotificationstats?.view)
              navigate(`/organization/${activeOrgId}/notification`, {
                state: {
                  filter: {
                    venues: venueId,
                    orgs: orgs.map(it => { return { orgId: it } }),
                    // venueChildren: false, 
                    alertImpact: [ALERT_MED],
                    alertType: ['alarm']
                  }
                }
              })
          }}>
            <Major className="mr-0" width={18} height={18} />
            <div className="w-25 text-right">
              {major}
            </div>
          </div>
        )}
      </div>
      {(major < 1 && critical < 1 && displayNone) ? <div>None</div> : <></>}
    </div>
  );
};

const listFormatter = (data, permissions = null, venuePermissions = null) => {
  let formattedData = data?.map((item) => {
    let newItem = {
      ...item,
      trafficData: !!item?.uplink ? formatNetworkCardData({ uplink: item?.uplink, downlink: item?.downlink }, true) : null,
      clientData: !!item?.clients ? item?.clients?.map((it) => { return { client: Number(it.device_count) } }) : null,
      leafNode: item.infraItemId ? true : item.leafNode,
    };
    if (item.venueId != null && item.infraItemId == null) {
      newItem.lat = newItem?.venueAddress?.latitude;
      newItem.lng = newItem?.venueAddress?.longitude;
    }
    delete newItem.uplink;
    delete newItem.downlink;
    delete newItem.clients;
    if (newItem.infraItemId != null && permissions != null) {
      let venueId = item.venueId;
      let dropdownEnabled = false,
        canEdit = permissions?.manageInfra?.update,
        canRetry = permissions?.manageFirmware?.update,
        canDelete = permissions?.manageInfra?.delete;
      let canReboot = canEdit;
      let canReschedule = canRetry;
      if (!!venueId) {
        let permi = venuePermissions[venueId];
        if (!!permi) {
          canEdit = permi?.infra?.update;
          canReboot = canEdit;
          canDelete = permi?.infra?.delete;
        }
      }
      if (canEdit || canRetry || canDelete) dropdownEnabled = true;
      newItem = {
        ...newItem,
        dropdownEnabled: dropdownEnabled,
        canEdit: canEdit,
        canRetry: canRetry,
        canDelete: canDelete,
        canReboot: canReboot,
        canReschedule: canReschedule,
      };
    }

    if (newItem.children?.length > 0) {
      return { ...newItem, children: listFormatter(item.children, permissions, venuePermissions) };
    } else {
      return newItem;
    }
  });
  return formattedData
};

const OrgWidget = (props) => {
  const {
    SCREEN_ID,
    headingText,
    autoFocus,
    disableCheck,
    listType,
    selections,
    filterReq = {},
    setSelections,
    venueSelection,
    removeBlueHighlight,
    showOrgOptions,
    setVenueSelection,
    disableCollapseRoot,
    treeStruct,
    hideAlarmIcons,
    showVenueOptions,
    getTreeStruct,
    enableOrgSelection
  } = props;
  const view = useSelector((store) => store.identity.meta.view);
  const activeOrg = useSelector((store) => store.activeOrg.data);
  const permissions = useSelector((store) => store?.rbac?.permissions);
  const venuePermissions = useSelector(
    (store) => store?.rbac?.venuePermissions
  );
  const importNotification = useSelector(store => store.identity.meta.importNotification);
  const moveNotification = useSelector(store => store.identity.meta.moveNotification);
  const deleteNotification = useSelector(store => store.identity.meta.deleteNotification);
  const identity = useSelector(store => store.identity);

  const [params, setParams] = useSearchParams();

  const [search, setSearch] = useState(params.get("search") ?? "");
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(true);
  const [listView, setListView] = useState(getViewType(SCREEN_ID) ?? "tree");
  const filteredColumns = (columns) => {
    let filteredCols = { ...columns }
    if (listType === LIST_TYPES.INFRA_LIST && listView === "tree") {
      delete filteredCols["Organization-Venue"];
      return filteredCols;
    }
    return columns;
  };
  const [cols, setCols] = useState(
    cmpCol(
      filteredColumns(columnObj[SCREEN_ID][view]),
      getColumns(SCREEN_ID, view) ?? {}
    )
  );
  const [isColumnSelectorOpen, setIsColumnSelectorOpen] = useState(false);
  const [list, setList] = useState([]);

  const [downgrade, setDowngrade] = useState();
  const [options, setOptions] = useState();

  const [deleteModal, setDeleteModal] = useState();
  const [subListClose, setSubListClose] = useState(false);
  const [deletionLoading, setDeletionLoading] = useState(false);
  const [deletingVenue, setDeletingVenue] = useState(null);

  const [deleteOrg, setDeleteOrg] = useState(null);

  const [mainFilter, setMainFilter] = useState(params.get("filter") ?? "All");
  const [filterCount, setFilterCount] = useState({});
  const [showFilter, setShowFilter] = useState(false);
  const [filterInitial, setFilterInitial] = useState({});

  const { state } = useLocation();
  const [filterActive, setFilterActive] = useState(!!state?.filter);
  const [filterSelection, setFilterSelection] = useState(
    state?.filter ? { ...state?.filter } : {}
  );
  const [filterData, setFilterData] = useState(
    state?.filter ? { ...state?.filter } : {}
  );

  const [downloadingList, setDownloadingList] = useState(false);
  const [sort, setSort] = useState(listType == LIST_TYPES.MSP_LIST ? {
    orderBy: "Alarms",
    order: "DESC",
  } : {
    orderBy: "Name",
    order: "ASC",
  });
  const [isSelector, setIsSelector] = useState(false);
  const [selectedVenue, setSelectedVenue] = useState(null);
  const [isMoveModalOpen, setIsMoveModalOpen] = useState(false);
  const [isDeleteInfraOpen, setIsDeleteInfraOpen] = useState(false);
  const [onlyOrgFilterApplied, setOnlyOrgFilterApplied] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const listRef = useRef();

  const formatColumns = (cols) => {
    return Object.keys(cols ?? {})
      .map((col) => (cols[col] ? apiColumns[col] ?? col : null))
      .filter((it) => it);
  };

  const getListParams = useCallback((newData) => {
    const currData = {
      offset: 0,
      limit: (
        listType == LIST_TYPES.INFRA_LIST &&
        (newData.listView != null ?
          newData.listView === 'tree'
          : listView === 'tree')
      ) ? 10 : 20,
      mainFilter: mainFilter,
      listView: listView,
      search: search,
      filterData: filterData,
      cols: cols
    };
    // if (newData?.listView === "flat" || (!newData?.listView && listView === "flat"))
    //   currData.limit = 20;
    const finalFilterData = {
      ...(newData.filterData ?? filterData),
      orgId: (newData.filterData ?? filterData).orgs?.map((org) => org.orgId)
    }
    delete finalFilterData?.orgs
    return { ...currData, ...newData, filterData: finalFilterData };
  }, [cols, filterData, listType, listView, mainFilter, search]
  );

  const getList = (data) => {
    const newData = getListParams(data ?? {});
    const { offset, limit, mainFilter, listView, search, filterData, cols } = newData;
    setLoading(true);
    if (offset == 0) setList([]);
    if (listType == LIST_TYPES.INFRA_LIST) {
      const columns = formatColumns(cols);
      const { run } = createRequest(
        services.telemetry.GET_INFRA_HIERARCHY_LIST,
        [
          activeOrg.orgId,
          offset,
          limit,
          columns,
          view,
          mainFilter,
          sort,
          encodeURIComponent(search),
          filterData,
          capitalizeWord(listView),
          false
        ]
      );

      run()
        .then((response) => {
          if (offset == 0) scrollToTop(listRef)
          if (response.data.length < limit) setHasMore(false);
          else setHasMore(true);
          if (offset == 0) setList(listFormatter(response.data, permissions, venuePermissions));
          else
            setList((prevState) => [
              ...prevState,
              ...listFormatter(response.data, permissions, venuePermissions),
            ]);
        })
        .catch(err => {
          make_custom_toast("error", "Error", new CatchedWebError(err).message)
        })
        .finally(() => setLoading(false));
    } else if ((listType == LIST_TYPES.MSP_LIST || listType == LIST_TYPES.ORG_LIST) && listView == "tree") {
      const columns = formatColumns(cols);
      const { run } = createRequest(services.telemetry.GET_ORG_LIST, [
        activeOrg.orgId,
        offset,
        limit,
        encodeURIComponent(search),
        mainFilter,
        sort,
        filterData,
        columns,
        view,
        listType == LIST_TYPES.MSP_LIST
      ]);

      run()
        .then((response) => {
          if (response.data.length < limit) setHasMore(false);
          if (activeOrg.orgTypeId == BUSINESS && listType == LIST_TYPES.ORG_LIST) {
            setList([]);
          }
          else {
            if (offset == 0) setList(listFormatter(response.data, permissions, venuePermissions));
            else
              setList((prevState) => [
                ...prevState,
                ...listFormatter(response.data, permissions, venuePermissions),
              ]);
          }
        })
        .catch(err => {
          make_custom_toast("error", "Error", new CatchedWebError(err).message)
        })
        .finally(() => setLoading(false));
    } else {
      const columns = formatColumns(cols);
      const { run } = createRequest(services.telemetry.GET_ORG_VENUE_LIST, [
        activeOrg.orgId,
        offset,
        listView == "map" ? 999999 : limit,
        encodeURIComponent(search),
        mainFilter,
        listView == "map" ? null : sort,
        listView == "tree" ? "Tree" : "Flat",
        filterData,
        listView == "map" ? mapColumns : columns,
        view,
        listType == LIST_TYPES.MSP_LIST, ,
        listView == "map"
      ]);

      run()
        .then((response) => {
          if (response.data.length < limit) setHasMore(false);
          if (offset == 0) setList(listFormatter(response.data, permissions, venuePermissions));
          else
            setList((prevState) => [
              ...prevState,
              ...listFormatter(response.data, permissions, venuePermissions),
            ]);
        })
        .catch(err => {
          make_custom_toast("error", "Error", new CatchedWebError(err).message)
        })
        .finally(() => setLoading(false));
    }
  };

  const getCountParams = (newData) => {
    const currData = {
      mainFilter: mainFilter,
      search: search,
      filterData: filterData,
      cols: cols,
      listView: listView
    };
    const finalFilterData = {
      ...(newData.filterData ?? filterData),
      orgId: (newData.filterData ?? filterData).orgs?.map((org) => org.orgId)
    }
    delete finalFilterData?.orgs
    return { ...currData, ...newData, filterData: finalFilterData };
  };
  const getCount = (data) => {
    const { search, cols, listView } = getCountParams(data ?? {});

    if (listType == LIST_TYPES.INFRA_LIST) {
      const columns = formatColumns(cols);
      const { run } = createRequest(
        services.telemetry.GET_INFRA_HIERARCHY_LIST_COUNT,
        [activeOrg.orgId, columns, view, encodeURIComponent(search), {}, false]
      );

      run().then((response) => {
        setFilterCount(response.data);
      });
    }
    else if ((listType == LIST_TYPES.MSP_LIST || listType == LIST_TYPES.ORG_LIST) && listView == "tree") {
      const columns =
        formatColumns(cols);

      const { run } = createRequest(
        services.telemetry.GET_ORG_LIST_COUNT,
        [
          activeOrg.orgId,
          encodeURIComponent(search),
          listType == LIST_TYPES.MSP_LIST,
          columns,
        ]
      );

      run().then((response) => {
        setFilterCount(response.data);
      });
    }
    else {
      const columns =
        formatColumns(cols);

      const { run } = createRequest(
        services.telemetry.GET_ORG_VENUE_LIST_COUNT,
        [
          activeOrg.orgId,
          encodeURIComponent(search),
          view,
          {},
          listView == "map" ? mapColumns : columns,
          listView == "map" && listType == LIST_TYPES.MSP_LIST,
          listView == "map"
        ]
      );

      run().then((response) => {
        setFilterCount(response.data);
      });
    }
  };

  const handleDownload = () => {
    if (listType == LIST_TYPES.MSP_LIST || listType == LIST_TYPES.ORG_LIST) {
      const allowedColumns = allowedColumnsObj[LIST_TYPES.MSP_LIST]
      const formattedColumns = formatColumns(cols)
      const filteredColumns = allowedColumns.filter(col => formattedColumns.includes(col))
      const apiURL = services.telemetry.DOWNLOAD_ORG_LIST.urlBuilder(...[
        activeOrg.orgId,
        view,
        filteredColumns,
        mainFilter,
        sort,
        search,
        filterData,
        listType == LIST_TYPES.MSP_LIST,
        capitalizeWord(listView),
      ])
      let mainFilterName;
      try {
        mainFilterName = filterObj[SCREEN_ID][view][mainFilter].name
      }
      catch (err) {

      }
      const fileName = listType == LIST_TYPES.MSP_LIST ?
        `Site Alarms List${!!mainFilter && !!mainFilterName && !filterActive ? ('-(' + capitalizeSentence(mainFilterName) + ')') : ''}${activeOrg?.orgId != null ? ('-(' + activeOrg.orgId + ')') : ''}.xlsx`
        : `Organization List${!!mainFilter && !!mainFilterName && !filterActive ? ('-(' + capitalizeSentence(mainFilterName) + ')') : ''}${activeOrg?.orgId != null ? ('-(' + activeOrg.orgId + ')') : ''}.xlsx`
      setDownloadingList(true)
      downloadSpreadsheet(apiURL, fileName, identity.meta.token)
        .catch(err => {
          make_custom_toast('error', listType == LIST_TYPES.MSP_LIST ? 'Export Site Alarms List' : 'Export Organization List', (new CatchedWebError(err)).message)
        })
        .finally(() => {
          setDownloadingList(false)
        })
    }
    else if (listType == LIST_TYPES.VENUE_LIST) {
      const allowedColumns = allowedColumnsObj[LIST_TYPES.VENUE_LIST]
      const formattedColumns = formatColumns(cols)
      const filteredColumns = allowedColumns.filter(col => formattedColumns.includes(col))
      const apiURL = services.telemetry.DOWNLOAD_VENUE_LIST.urlBuilder(...[
        activeOrg.orgId,
        view,
        filteredColumns,
        mainFilter,
        sort,
        search,
        capitalizeWord(listView),
        { ...(filterData ?? {}), orgId: filterData?.orgs?.map(org => org.orgId) }
      ])
      let mainFilterName;
      try {
        mainFilterName = filterObj[SCREEN_ID][view][mainFilter].name
      }
      catch (err) {

      }
      const fileName = `Venue List${!!mainFilter && !!mainFilterName && !filterActive ? ('-(' + capitalizeSentence(mainFilterName) + ')') : ''}${activeOrg?.orgId != null ? ('-(' + activeOrg.orgId + ')') : ''}.xlsx`;
      setDownloadingList(true)
      downloadSpreadsheet(apiURL, fileName, identity.meta.token)
        .catch(err => {
          make_custom_toast('error', 'Export Venue List', (new CatchedWebError(err)).message)
        })
        .finally(() => {
          setDownloadingList(false)
        })
    }
    else if (listType == LIST_TYPES.INFRA_LIST) {
      const allowedColumns = allowedColumnsObj[LIST_TYPES.INFRA_LIST]
      const formattedColumns = formatColumns(cols)
      const filteredColumns = allowedColumns.filter(col => formattedColumns.includes(col))
      const apiURL = services.telemetry.DOWNLOAD_INFRA_LIST.urlBuilder(...[
        activeOrg.orgId,
        capitalizeWord(listView),
        view,
        filteredColumns,
        mainFilter,
        sort,
        search,
        { ...(filterData ?? {}), orgId: filterData?.orgs?.map(org => org.orgId) }
      ])
      let mainFilterName;
      try {
        mainFilterName = filterObj[SCREEN_ID][view][mainFilter].name
      }
      catch (err) {

      }
      const fileName = `Infrastructure List${!!mainFilter && !!mainFilterName && !filterActive ? ('-(' + capitalizeSentence(mainFilterName) + ')') : ''}${activeOrg?.orgId != null ? ('-(' + activeOrg.orgId + ')') : ''}.xlsx`;
      setDownloadingList(true)
      downloadSpreadsheet(apiURL, fileName, identity.meta.token)
        .catch(err => {
          make_custom_toast('error', 'Export Infrastructure List', (new CatchedWebError(err)).message)
        })
        .finally(() => {
          setDownloadingList(false)
        })
    }
  }

  useEffect(() => {
    if (filterReq.OrgFilter) {
      let filterWithoutOrgs = { ...(filterData ?? {}) }
      let filterInitialWithoutOrgs = { ...(filterInitial ?? {}) }
      delete filterWithoutOrgs.orgs
      delete filterInitialWithoutOrgs.orgs
      if (search?.length === 0 &&
        filterActive &&
        // filterData?.orgs?.length == 1 &&
        (lodash.isEqual(filterWithoutOrgs, filterInitialWithoutOrgs))
      )
        setOnlyOrgFilterApplied(true)
      else setOnlyOrgFilterApplied(false)
    }
  }, [filterData, filterInitial, search, listType, filterReq])


  useEffect(() => {
    if (listType == LIST_TYPES.INFRA_LIST) {
      let actionCompleted = importNotification?.bulkActionStatus == 'Completed' ||
        moveNotification?.bulkActionStatus == 'Completed' ||
        deleteNotification?.bulkActionStatus == 'Completed'
      if (actionCompleted && !loading) {
        getList()
        getCount()
      }
    }
  }, [importNotification, moveNotification, deleteNotification])

  useEffect(() => {
    if (!isSelector && setSelections != null && setVenueSelection != null) {
      setSelections({ infras: {}, venues: {} })
      setVenueSelection({
        venues: new Set(),
        infras: new Set(),
        venueDelete: new Set(),
        infraDelete: new Set(),
        applyToOrg: null
      })
      setSelectedVenue(null)
    }
  }, [isSelector])

  useEffect(() => {
    if (!!venueSelection && venueSelection.venues?.size == 0 && venueSelection.infras?.size == 0 && venueSelection.applyToOrg != null)
      setVenueSelection(prevState => {
        return {
          ...prevState,
          applyToOrg: null
        }
      })
  }, [venueSelection])

  useEffect(() => {
    const newCols = cmpCol(
      filteredColumns(columnObj[SCREEN_ID][view]),
      getColumns(SCREEN_ID, view) ?? {}
    );
    setCols(newCols);
    if (!!filterObj[SCREEN_ID]) {
      let newMainFilter = mainFilter
      if (filterObj[SCREEN_ID][view][newMainFilter] == null) {
        newMainFilter = Object.keys(filterObj[SCREEN_ID][view])[0];
      }
      setMainFilter(newMainFilter);
      if (listType == LIST_TYPES.INFRA_LIST) {
        getList({ mainFilter: newMainFilter, cols: newCols });
        getCount({ mainFilter: newMainFilter, cols: newCols });
      } else {
        getList({ mainFilter: newMainFilter, limit: 20, cols: newCols });
        getCount({ mainFilter: newMainFilter, cols: newCols });
      }
    }
  }, [view, sort]);

  useEffect(() => {
    let updatedSearchParams = new URLSearchParams(params.toString());
    updatedSearchParams.set("filter", mainFilter);
    setParams(updatedSearchParams.toString(), { replace: true });
  }, [mainFilter]);

  useEffect(() => {
    const filterTemp = {
      OrgFilter: "orgs",
      OperationsFilter: "operations",
      LocationFilter: "location",
      VenueStateFilter: "state",
      OrgStateFilter: "state",
      FWFilter: "firmwareVersion",
      CategoryFilter: "infraCategory",
      InfraTypeFilter: "infraType",
      StatusFilter: "status",
    };
    Object.keys(filterReq ?? {}).map((filter) => {
      if (filterReq[filter]) {
        if (filter == "CategoryFilter") {
          setFilterInitial((prevState) => {
            return { ...prevState, [filterTemp[filter]]: "ALL" };
          });
          if (!state?.filter) {
            setFilterSelection((prevState) => {
              return { ...prevState, [filterTemp[filter]]: "ALL" };
            });
            setFilterData((prevState) => {
              return { ...prevState, [filterTemp[filter]]: "ALL" };
            });
          }
        } else {
          setFilterInitial((prevState) => {
            return { ...prevState, [filterTemp[filter]]: [] };
          });
          if (!state?.filter) {
            setFilterSelection((prevState) => {
              return { ...prevState, [filterTemp[filter]]: [] };
            });
            setFilterData((prevState) => {
              return { ...prevState, [filterTemp[filter]]: [] };
            });
          }
        }
      }
    });
  }, []);

  const deleteVenue = useCallback(
    (deleteVenueId) => {
      const { context, run } = createRequest(services.venue.DELETE, [
        deleteVenueId,
      ]);

      setDeletionLoading(true);
      run()
        .then((response) => {
          setList((currList) =>
            currList?.filter((item) => item.venueId != deleteVenueId)
          );
          getCount();
          setSubListClose(true);
          setDeleteModal(null);
        })
        .catch((err) => {
          let x = new CatchedWebError(err);
          const apiContext = createErrorContext(
            context,
            "Deleting Venue",
            TICKET_CATEGORY.VENUE,
            err
          );
          // setDeletionError(x.message);
          make_custom_toast(
            "error",
            "Venue",
            x.message,
            true,
            "Create Ticket",
            () => {
              navigate(
                `/organization/${activeOrg.orgId}/support/ticket/createticket/${apiContext.action}/${apiContext.category}`,
                {
                  state: {
                    ticketContext: apiContext,
                  },
                }
              );
            }
          );
        })
        .finally(() => {
          setDeletionLoading(false);
          setDeleteModal(null);
          setTimeout(() => setSubListClose(false), 1000);
        });
    },
    [navigate, activeOrg]
  );

  const deleteInfra = (deleteInfraId, orgId) => {
    const UUID = uuidv4();
    const { context, run } = Array.isArray(deleteInfraId) ?
      createRequest(services.infra.BULK_DELETE, [
        orgId,
        UUID
      ],
        deleteInfraId
      )
      : createRequest(services.infra.DELETE, [
        deleteInfraId,
      ])

    setDeletionLoading(true);
    run()
      .then((response) => {
        dispatch(identityActions.setDeleteUUID(UUID));
        if (!loading) {
          getList()
        }
        getCount()
        // setList((currList) =>
        //   currList?.filter((item) => item.infraItemId != deleteInfraId)
        // );
        // setSubListClose(true);
        setIsDeleteInfraOpen(null);
        setIsSelector(false);
        make_custom_toast(
          'success',
          'Delete Infrastructure',
          'Deleting Infrastructure'
        )
      })
      .catch((err) => {
        let x = new CatchedWebError(err);
        const apiContext = createErrorContext(
          context,
          "Deleting Infrastructure",
          TICKET_CATEGORY.INFRASTRUCTURE,
          err
        );
        // setDeletionError(x.message);
        make_custom_toast(
          "error",
          "Venue",
          x.message,
          true,
          "Create Ticket",
          () => {
            navigate(
              `/organization/${activeOrg.orgId}/support/ticket/createticket/${apiContext.action}/${apiContext.category}`,
              {
                state: {
                  ticketContext: apiContext,
                },
              }
            );
          }
        );
      })
      .finally(() => {
        setDeletionLoading(false);
        setIsDeleteInfraOpen(null);
        // setTimeout(() => setSubListClose(false), 1000);
      });
  };

  return (
    <div className="OrgWidget" data-testid="OrgWidget">
      <Suspense fallback={<></>}>
        <MoveInfraModal
          isOpen={isMoveModalOpen}
          setIsOpen={setIsMoveModalOpen}
          selectedVenue={selectedVenue}
          setSelectedVenue={setSelectedVenue}
          infraIds={venueSelection?.infras}
          onSuccess={() => {
            setIsSelector(false);
            setIsMoveModalOpen(false);
            setSelectedVenue(null);
          }}
          onCancel={() => {
            // setIsSelector(false);
            // setVenueSelection({
            //   venues: new Set(),
            //   infras: new Set(),
            //   venueDelete: new Set(),
            //   infraDelete: new Set(),
            // });
            setSelectedVenue(null)
            setIsMoveModalOpen(false);
          }}
          orgId={venueSelection?.applyToOrg}
        />
      </Suspense>
      <Suspense fallback={<></>}>
        <DeleteInfraModal
          isOpen={isDeleteInfraOpen}
          setIsOpen={setIsDeleteInfraOpen}
          onDelete={() => {
            if (isDeleteInfraOpen?.type == deleteTypes.INFRAS)
              deleteInfra(Array.from(isDeleteInfraOpen?.infraIds ?? []), isDeleteInfraOpen?.orgId)
            else
              deleteInfra(isDeleteInfraOpen?.infraId, isDeleteInfraOpen?.orgId)
          }}
          infraIds={isDeleteInfraOpen?.type == deleteTypes.INFRAS ? isDeleteInfraOpen?.infraIds : new Set([isDeleteInfraOpen?.infraId])}
          disabled={deletionLoading}
          orgId={isDeleteInfraOpen?.orgId}
        />
      </Suspense>
      <Suspense fallback={<></>}>
        <FilterSetter
          // disabled={!Object.keys(filterSelection).length}
          showFilter={showFilter}
          elements={() => [
            <OrgFilter
              hide={!filterReq.OrgFilter}
              filter={filterSelection}
              setFilter={setFilterSelection}
            />,
            <OperationsFilter
              hide={!filterReq.OperationsFilter}
              filter={filterSelection}
              setFilter={setFilterSelection}
            />,
            <LocationFilter
              hide={!filterReq.LocationFilter}
              filter={filterSelection}
              setFilter={setFilterSelection}
            />,
            <VenueStateFilter
              hide={!filterReq.VenueStateFilter}
              filter={filterSelection}
              setFilter={setFilterSelection}
            />,
            <OrgStateFilter
              hide={!filterReq.OrgStateFilter}
              filter={filterSelection}
              setFilter={setFilterSelection}
            />,
            <CategoryFilter
              hide={!filterReq.CategoryFilter}
              filter={filterSelection}
              setFilter={setFilterSelection}
            />,
            <InfraTypeFilter
              hide={!filterReq.InfraTypeFilter}
              filter={filterSelection}
              setFilter={setFilterSelection}
            />,
            <ConnectedFilter
              hide={!filterReq.StatusFilter}
              filter={filterSelection}
              setFilter={setFilterSelection}
            />,
            <FWFilter
              hide={!filterReq.FWFilter}
              filter={filterSelection}
              setFilter={setFilterSelection}
            />,
          ]}
          handleApplyClick={() => {
            if (!loading) {
              setHasMore(true);
              setMainFilter("All");
              getList({
                offset: 0,
                mainFilter: "All",
                filterData: filterSelection,
              });
              setFilterData(filterSelection);
              if (lodash.isEqual(filterSelection, filterInitial))
                setFilterActive(false);
              else setFilterActive(true);
              setShowFilter(false);
            }
          }}
          handleClearAll={() => {
            if (!loading) {
              setHasMore(true);
              setFilterActive(false);
              setFilterSelection(filterInitial);
              setFilterData(filterInitial);
              getList({
                offset: 0,
                mainFilter: mainFilter,
                filterData: filterInitial,
              });
              setShowFilter(false);
            }
          }}
          setShowFilter={setShowFilter}
        />
      </Suspense>
      <Suspense fallback={<></>}>
        <SidePanel
          isOpen={isColumnSelectorOpen}
          setIsOpen={setIsColumnSelectorOpen}
        >
          <Suspense fallback={<></>}>
            <ColSelector
              cols={cols}
              setCols={setCols}
              setIsOpen={setIsColumnSelectorOpen}
              onApply={(newCol) => {
                setColumns(SCREEN_ID, newCol, view);
                setHasMore(true);
                getList({ offset: 0, limit: 20, cols: newCol });
                getCount({ cols: newCol });
              }}
            />
          </Suspense>
        </SidePanel>
      </Suspense>

      {(listType == LIST_TYPES.MSP_LIST && !loading && list.length == 0 && search.length == 0 && mainFilter == "All" && !filterActive) ?
        <div>
          <div>
            {headingText != null ? headingText : null}
          </div>
          <div className="text-center">
            <h4 className="font-weight p-3">No Critical and Major Alarm</h4>
          </div>
        </div> :
        <div>
          <div className="container-fluid">
            <Row className="mb-1">

              {/* Search and Filter */}
              <Col md={(listType != LIST_TYPES.ORG_LIST) ? 12 : 10} className="p-0 mt-0 mt-md-50 col-xxxl-10">
                <div className="d-flex align-items-center">
                  {headingText != null ? headingText : null}
                  <InputGroup className="input-group-merge" style={{ width: "310px" }}>
                    <Input
                      autoFocus={autoFocus}
                      type="text"
                      placeholder="Search"
                      style={{ height: "2.4rem" }}
                      defaultValue={search}
                      onChange={(e) => {
                        if (
                          e.target.value.trim().length > 1 ||
                          e.target.value.trim().length === 0
                        ) {
                          debounce(() => {
                            setList([]);
                            setSearch(e.target.value.trim());
                            setHasMore(true);
                            if (listType == LIST_TYPES.INFRA_LIST) {
                              getList({ search: e.target.value.trim() });
                              getCount({ search: e.target.value.trim() });
                            } else {
                              getList({ search: e.target.value.trim(), limit: 20 });
                              getCount({ search: e.target.value.trim() });
                            }
                          });
                        }
                        // else {
                        //   debounce(() => {
                        //     setSearch("");
                        //     setHasMore(true);
                        //     if (listType == LIST_TYPES.INFRA_LIST) {
                        //       getList({ search: e.target.value.trim() });
                        //       getCount({ search: e.target.value.trim() });
                        //     } else {
                        //       getList({ search: e.target.value.trim(), limit: 20 });
                        //       getCount({ search: e.target.value.trim() });
                        //     }
                        //   });
                        // }
                      }}
                    />
                    <SearchIconAddon />
                  </InputGroup>
                  <div className="ml-1">
                    <GroupButton>
                      {filterObj[SCREEN_ID] &&
                        Object.keys(filterObj[SCREEN_ID][view]).map((apiValue, key) => {
                          const filterItem = filterObj[SCREEN_ID][view][apiValue];
                          return (
                            <div
                              className={
                                "grp-btn-custom " +
                                (mainFilter == apiValue && !filterActive
                                  ? "active"
                                  : "")
                              }
                              onClick={() => {
                                if (!loading) {
                                  setMainFilter(apiValue);
                                  setFilterActive(false);
                                  setFilterSelection(filterInitial);
                                  setFilterData(filterInitial);
                                  setHasMore(true);
                                  if (listType == LIST_TYPES.INFRA_LIST)
                                    getList({
                                      mainFilter: apiValue, filterData: filterInitial
                                    });
                                  else getList({ mainFilter: apiValue, limit: 20, filterData: filterInitial });
                                }
                              }}
                              key={`${key}-org-widget-main-filter`}
                            >
                              {filterItem.name} (
                              {filterCount[filterItem?.countName] ?? 0})
                            </div>
                          );
                        })}
                    </GroupButton>
                  </div>
                  <FilterButton
                    className="ml-50"
                    size={22}
                    active={filterActive}
                    onClick={() => setShowFilter(true)}
                    style={{ padding: "0.36rem" }}
                  />
                </div>
              </Col>

              <Col md={(listType != LIST_TYPES.ORG_LIST) ? 12 : 2} className="p-0  mt-0 mt-md-50 col-xxxl-2">
                <div className="h-100">
                  {/* Infra Options */}
                  {listType === LIST_TYPES.INFRA_LIST ? (
                    <div className="d-flex justify-content-end align-items-center h-100">
                      <div className={`h-100 ${isSelector ? 'mr-1' : ''}`}>
                        <GroupButton className="h-100 align-items-center">
                          <div
                            className={
                              "grp-btn-custom grp-btn-icon-div d-flex align-items-center justify-content-center " +
                              (listView === "tree" ? "active " : "")
                            }
                            data-clickDisabled={isSelector && listView !== "tree"}
                            onClick={() => {
                              if (!loading && !isSelector) {
                                setListView("tree");
                                setCols((prevState) => {
                                  if (
                                    listType === LIST_TYPES.INFRA_LIST &&
                                    prevState["Organization-Venue"] != null
                                  ) {
                                    const newCols = { ...prevState };
                                    delete newCols["Organization-Venue"];
                                    getList({ listView: "tree", cols: newCols });
                                    return newCols;
                                  } else getList({ listView: "tree" });
                                  return prevState;
                                });
                                setViewType(SCREEN_ID, "tree");
                              }
                            }}
                          >
                            <span
                              className="material-symbols-outlined"
                              // style={{ fontSize: "14px" }}
                              style={{ fontSize: "1.4rem" }}
                            >
                              account_tree
                            </span>
                            {/* tree */}
                          </div>
                          <div
                            className={
                              "grp-btn-custom grp-btn-icon-div d-flex align-items-center justify-content-center " +
                              (listView === "flat" ? "active" : "")
                            }
                            data-clickDisabled={isSelector && listView !== 'flat'}
                            onClick={() => {
                              if (!loading && !isSelector) {
                                setListView("flat");
                                setCols((prevState) => {
                                  if (prevState["Organization-Venue"] == null) {
                                    const newCols = { ...prevState };
                                    let getColValue = getColumns(SCREEN_ID, view) ?? {}
                                    newCols["Organization-Venue"] =
                                      getColValue["Organization-Venue"] ??
                                      columnObj[SCREEN_ID][view]["Organization-Venue"];
                                    getList({ listView: "flat", cols: newCols });
                                    return newCols;
                                  }
                                  return prevState;
                                });
                                setViewType(SCREEN_ID, "flat");
                              }
                            }}
                          >
                            {/* <List height= '14px' width='16px' className="list-icon" /> */}
                            <List height='1.4rem' width='1.4rem' className="list-icon" />
                            {/* flat */}
                          </div>
                        </GroupButton>
                      </div>
                      {isSelector ? (
                        <Suspense fallback={<></>}>
                          <BulkActions
                            selectionLength={venueSelection.infras.size}
                            onMoveClick={() => {
                              if (venueSelection.infras.size == 0) return;
                              setIsMoveModalOpen(true);
                            }}
                            onDeleteClick={() => {
                              if (venueSelection.infras.size == 0) return;
                              setIsDeleteInfraOpen({
                                type: deleteTypes.INFRAS,
                                infraIds: venueSelection?.infras,
                                orgId: venueSelection?.applyToOrg
                              });
                            }}
                            onCancel={() => {
                              setIsSelector(false);
                              setVenueSelection({
                                venues: new Set(),
                                infras: new Set(),
                                venueDelete: new Set(),
                                infraDelete: new Set(),
                                applyToOrg: null
                              });
                            }}
                          />
                        </Suspense>
                      ) : (
                        <UncontrolledDropdown
                          className="top-dropdown h-100"
                          direction="down"
                          disabled={false}
                        >
                          <DropdownToggle
                            color="white"
                            className="p-0 h-100"
                            size="sm"
                          >
                            <span className="material-symbols-outlined text-primary">more_vert</span>
                          </DropdownToggle>
                          <DropdownMenu>
                            {activeOrg.orgTypeId == BUSINESS &&
                              permissions?.manageVenue?.view &&
                              (permissions?.manageInfra?.create ||
                                venuePermissions?.infra?.create) ? (
                              <>
                                <DropdownItem
                                  className="w-100 d-flex align-items-center"
                                  onClick={() => {
                                    navigate(
                                      `/organization/${activeOrg.orgId}/infra/add/`
                                    );
                                  }}
                                >
                                  <span className="material-symbols-outlined">add</span>
                                  Add
                                </DropdownItem>
                                <DropdownItem
                                  className="w-100 d-flex align-items-center"
                                  disabled={!!importNotification || !!moveNotification || !!deleteNotification}
                                  onClick={() =>
                                    navigate(
                                      `/organization/${activeOrg.orgId}/infra/import/`
                                    )
                                  }
                                >
                                  <span className="material-symbols-outlined">
                                    download
                                  </span>
                                  Import
                                </DropdownItem>
                              </>
                            ) : null}
                            <DropdownItem className="w-100 d-flex align-items-center"
                              disabled={downloadingList}
                              onClick={() => {
                                handleDownload()
                              }}
                            >
                              <div
                                title={`Export List\n(maximum limit: 5000)`}
                                className="d-flex align-items-center"
                              >
                                <CsvIconGrey
                                  height={18}
                                  width={24}
                                  className={`mr-50 ${downloadingList
                                    ? "list-download-icon--disabled"
                                    : "cursor-pointer"
                                    }`}
                                  disabled={downloadingList}
                                />
                                Export
                              </div>
                            </DropdownItem>
                            {permissions?.manageInfra?.delete ||
                              permissions?.manageInfra?.update ? (
                              <DropdownItem
                                className="w-100 d-flex align-items-center"
                                onClick={() => setIsSelector((prevState) => !prevState)}
                                disabled={!!importNotification || !!moveNotification || !!deleteNotification}
                              >
                                <span className="material-symbols-outlined">stacks</span>
                                Bulk Edit
                              </DropdownItem>
                            ) : null}
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      )}
                    </div>
                  ) : null}

                  {/* Dashboard Options */}
                  {listType === LIST_TYPES.MSP_LIST ? (
                    <div className="d-flex justify-content-end align-items-center h-100">
                      <div className="h-100 mr-1">
                        <GroupButton className="h-100 d-flex align-items-center">
                          <div
                            className={
                              "grp-btn-custom grp-btn-icon-div d-flex align-items-center justify-content-center " +
                              (listView === "tree" ? "active" : "")
                            }
                            onClick={() => {
                              if (!loading) {
                                setListView("tree");
                                getList({ listView: "tree", limit: 20 });
                                getCount({ listView: "tree" });
                                setViewType(SCREEN_ID, "tree");
                              }
                            }}
                          >
                            <span
                              className="material-symbols-outlined"
                              // style={{ fontSize: "14px" }}
                              style={{ fontSize: "1.4rem" }}
                            >
                              account_tree
                            </span>
                            {/* tree */}
                          </div>
                          <div
                            className={
                              "grp-btn-custom grp-btn-icon-div d-flex align-items-center justify-content-center " +
                              (listView === "map" ? "active" : "")
                            }
                            onClick={() => {
                              if (!loading) {
                                getList(
                                  { listView: "map", limit: 99999 },
                                );
                                setListView("map");
                                getCount({ listView: "map" });
                                setViewType(SCREEN_ID, "map");
                              }
                            }}
                          >
                            <span
                              className="material-symbols-outlined"
                              // style={{ fontSize: "14px" }}
                              style={{ fontSize: "1.4rem" }}
                            >
                              public
                            </span>
                          </div>
                        </GroupButton>
                      </div>
                      <div className="csv-download-icon ml-50">
                        <CsvIcon
                          height={24}
                          width={24}
                          className={`${downloadingList || listView == 'map'
                            ? "list-download-icon--disabled"
                            : "cursor-pointer"
                            }`}
                          onClick={() => {
                            if (!downloadingList && listView != 'map') {
                              handleDownload()
                            }
                          }}
                          title={`Export List\n(maximum limit: 5000)`}
                        />
                      </div>
                    </div>
                  ) : null}

                  {/* Org & Venue Options */}
                  {listType === LIST_TYPES.VENUE_LIST || listType === LIST_TYPES.ORG_LIST ? (
                    <div className="d-flex justify-content-end h-100">
                      {listType == LIST_TYPES.VENUE_LIST ?
                        <div>
                          <GroupButton className="h-100 d-flex align-items-center">
                            <div
                              className={
                                "grp-btn-custom grp-btn-icon-div d-flex align-items-center justify-content-center " +
                                (listView === "tree" ? "active" : "")
                              }
                              onClick={() => {
                                if (!loading) {
                                  setListView("tree");
                                  getList({ listView: "tree", limit: 20 });
                                  getCount({ listView: "tree" });
                                  setViewType(SCREEN_ID, "tree");
                                }
                              }}
                            >
                              <span
                                className="material-symbols-outlined"
                                style={{ fontSize: "1.4rem" }}
                              // style={{ fontSize: "14px" }}
                              >
                                account_tree
                              </span>
                              {/* tree */}
                            </div>
                            <div
                              className={
                                "grp-btn-custom grp-btn-icon-div d-flex align-items-center justify-content-center " +
                                (listView === "map" ? "active" : "")
                              }
                              onClick={() => {
                                if (!loading) {
                                  getList(
                                    { listView: "map", limit: 99999 },
                                  );
                                  getCount({ listView: "map" });
                                  setListView("map");
                                  setViewType(SCREEN_ID, "map");
                                }
                              }}
                            >
                              <span
                                className="material-symbols-outlined"
                                // style={{ fontSize: "14px" }}
                                style={{ fontSize: "1.4rem" }}
                              >
                                public
                              </span>
                            </div>
                          </GroupButton>
                        </div>
                        : <></>
                      }
                      <UncontrolledDropdown
                        className="top-dropdown h-100"
                        direction="down"
                      >
                        <DropdownToggle
                          color="white"
                          className="p-0 h-100"
                          size="sm"
                        >
                          <span className="material-symbols-outlined text-primary">more_vert</span>
                        </DropdownToggle>
                        {listType == LIST_TYPES.VENUE_LIST ?
                          <DropdownMenu>
                            {(permissions?.manageVenue?.create && activeOrg.orgTypeId == BUSINESS) ?
                              <>
                                <DropdownItem
                                  className="w-100 d-flex align-items-center"
                                  disabled={!(permissions?.manageVenue?.create) || !(activeOrg.orgTypeId != MSP)}
                                  onClick={() => {
                                    navigate(`/organization/${activeOrg.orgId}/venues/add/`)
                                  }}
                                >
                                  <span className="material-symbols-outlined">add</span>
                                  Add
                                </DropdownItem>
                                <DropdownItem
                                  className="w-100 d-flex align-items-center"
                                  disabled={!(permissions?.manageVenue?.create) || !(activeOrg.orgTypeId == BUSINESS)}
                                  onClick={() => {
                                    navigate(`/organization/${activeOrg.orgId}/venues/import/`)
                                  }}
                                >
                                  <span className="material-symbols-outlined">download</span>
                                  Import
                                </DropdownItem>
                              </> : null}
                            <DropdownItem className="w-100 d-flex align-items-center"
                              disabled={downloadingList}
                              onClick={() => {
                                handleDownload()
                              }}
                            >
                              <div
                                title={`Export List\n(maximum limit: 5000)`}
                                className="d-flex align-items-center"
                              >
                                <CsvIconGrey
                                  height={18}
                                  width={24}
                                  className={`mr-50 ${downloadingList
                                    ? "list-download-icon--disabled"
                                    : "cursor-pointer"
                                    }`}
                                  disabled={downloadingList}
                                />
                                Export
                              </div>
                            </DropdownItem>
                          </DropdownMenu> :
                          <DropdownMenu>
                            {activeOrg.orgTypeId != BUSINESS && permissions?.manageOrganization?.create ?
                              <DropdownItem
                                className="w-100 d-flex align-items-center"
                                onClick={() => {
                                  navigate(`/organization/${activeOrg.orgId}/sub/add`)
                                }}
                              >
                                <span className="material-symbols-outlined">add</span>
                                Add
                              </DropdownItem> : null}
                            <DropdownItem className="w-100 d-flex align-items-center"
                              disabled={downloadingList}
                              onClick={() => {
                                handleDownload()
                              }}
                            >
                              <div
                                title={`Export List\n(maximum limit: 5000)`}
                                className="d-flex align-items-center"
                              >
                                <CsvIconGrey
                                  height={18}
                                  width={24}
                                  className={`mr-50 ${downloadingList
                                    ? "list-download-icon--disabled"
                                    : "cursor-pointer"
                                    }`}
                                  disabled={downloadingList}
                                />
                                Export
                              </div>
                            </DropdownItem>
                          </DropdownMenu>}
                      </UncontrolledDropdown>
                    </div>
                  ) : null}
                </div>
              </Col>
              {/* {(listType == LIST_TYPES.ORG_LIST && activeOrg.orgTypeId != BUSINESS)? <div className="d-flex justify-content-end text-right">
              <React.Fragment>
                <Link to={`/organization/${activeOrg.orgId}/sub/add`} className="rounded btn-primary bg-white small-add-button">Add</Link> */}
              {/* <Button color="primary" onClick={() => { inviteModalState[1](true); }}>Invite</Button> */}
              {/* </React.Fragment>
            </div>
            : null} */}
            </Row>
          </div>


          {listView == "tree" || listView == "flat" ? (
            <InfiniteScroll
              dataLength={list.length}
              next={() => {
                if (listType == LIST_TYPES.INFRA_LIST)
                  getList({
                    offset: list.length,
                  });
                else getList({ offset: list.length, limit: 20 });
              }}
              hasMore={hasMore}
              loader={<></>}
              scrollableTarget="org-list"
              endMessage={<></>}
            >
              <div id="org-list" ref={listRef}>
                <OrgListContext.Provider
                  value={{
                    cols: cols,
                    sort: sort,
                    disableCheck: disableCheck,
                    filterActive: filterActive,
                    isSelector: isSelector ?? false,
                    mainFilter: mainFilter,
                    selections: selections,
                    deletingVenue: deletingVenue,
                    showVenueOptions: showVenueOptions,
                    setSelections: setSelections,
                    search: search,
                    showOrgOptions: showOrgOptions,
                    hideAlarmIcons: hideAlarmIcons,
                    removeBlueHighlight: removeBlueHighlight,
                    treeStruct: treeStruct,
                    subListClose: subListClose,
                    disableCollapseRoot: disableCollapseRoot,
                    venueSelection: venueSelection,
                    setVenueSelection: setVenueSelection,
                    setDeleteItem: setDeleteOrg,
                    setDeletingVenue: setDeletingVenue,
                    setDeleteModal: setDeleteModal,
                    setDowngrade: setDowngrade,
                    setOptions: setOptions,
                    getTreeStruct: getTreeStruct,
                    setIsSelector: setIsSelector,
                    setIsDeleteInfraOpen: setIsDeleteInfraOpen,
                    onlyOrgFilterApplied: onlyOrgFilterApplied,
                    enableOrgSelection: enableOrgSelection,
                    listView: listView,
                    listType: listType
                  }}
                >
                  <Table className="table-view fixed-header">
                    <thead>
                      <tr>
                        {cols.Status && listView === "flat" ? (
                          <th style={{ minWidth: "125px" }}>
                            <ColumnHeader header="Status" />
                          </th>
                        ) : null}
                        <th
                          style={{
                            minWidth: listView === "tree" ? "350px" : "280px",
                          }}
                        >
                          <ColumnHeader
                            header="Name"
                            attribute="Name"
                            sort={sort}
                            setter={setSort}
                          />
                        </th>
                        {listType == LIST_TYPES.INFRA_LIST &&
                          listView === 'tree' && (
                            <th style={{ minWidth: "60px" }}></th>
                          )}
                        {cols.Alarms && (
                          <th style={{ minWidth: "150px" }}>
                            <ColumnHeader
                              right
                              header="Alarms"
                              attribute="Alarms"
                              sort={sort}
                              setter={setSort}
                            />
                          </th>
                        )}
                        {cols.State && (
                          <th style={{ minWidth: "100px" }}>
                            <ColumnHeader
                              header="State"
                              attribute={listType == LIST_TYPES.MSP_LIST ? null : "State"}
                              sort={sort}
                              setter={setSort}
                            />
                          </th>
                        )}
                        {cols.Status && listView === "tree" ? (
                          <th style={{ minWidth: "100px" }}>
                            <ColumnHeader header="Status" />
                          </th>
                        ) : null}
                        {listType != LIST_TYPES.INFRA_LIST && cols.Operations && (
                          <th className="" style={{ minWidth: "150px" }}>
                            <ColumnHeader header="Operations" />
                          </th>
                        )}
                        {cols["Organization-Venue"] && (
                          <th style={{ minWidth: "250px" }}>
                            <ColumnHeader
                              header="Organization & Venue"
                              attribute={"Organization-Venue"}
                              setter={setSort}
                              sort={sort}
                            />
                          </th>
                        )}
                        {cols["Tasks"] && (
                          <th style={{ minWidth: "200px" }}>
                            <div className="pl-1">
                              <ColumnHeader header="Tasks" />
                            </div>
                          </th>
                        )}
                        {cols["Room"] && (
                          <th style={{ minWidth: "170px" }}>
                            <ColumnHeader
                              header="Room"
                              attribute={"Room"}
                              setter={setSort}
                              sort={sort}
                            />
                          </th>
                        )}
                        {cols["Location"] && (
                          <th style={{ minWidth: "120px" }}>
                            <ColumnHeader
                              header="Location"
                              attribute={"Location"}
                              setter={setSort}
                              sort={sort}
                            />
                          </th>
                        )}
                        {cols["MAC Address"] && (
                          <th style={{ minWidth: "150px" }}>
                            <ColumnHeader header="MAC Address" />
                          </th>
                        )}
                        {cols["Private IP"] && (
                          <th style={{ minWidth: "130px" }}>
                            <ColumnHeader
                              header="Private IP"
                              attribute={"Private IP"}
                              setter={setSort}
                              sort={sort}
                            />
                          </th>
                        )}
                        {cols["Public IP"] && (
                          <th style={{ minWidth: "130px" }}>
                            <ColumnHeader
                              header="Public IP"
                              attribute={"Public IP"}
                              setter={setSort}
                              sort={sort}
                            />
                          </th>
                        )}
                        {cols["AP Profile"] && (
                          <th style={{ minWidth: "170px" }}>
                            <ColumnHeader
                              header="AP Profile"
                              attribute={"AP Profile"}
                              setter={setSort}
                              sort={sort}
                            />
                          </th>
                        )}
                        {cols["Switch Profile"] && (
                          <th style={{ minWidth: "170px" }}>
                            <ColumnHeader
                              header="Switch Profile"
                              attribute={"Switch Profile"}
                              setter={setSort}
                              sort={sort}
                            />
                          </th>
                        )}
                        {cols["RF Profile"] && (
                          <th style={{ minWidth: "170px" }}>
                            <ColumnHeader
                              header="RF Profile"
                              attribute={"RF Profile"}
                              setter={setSort}
                              sort={sort}
                            />
                          </th>
                        )}
                        {cols["Radio 2G"] && (
                          <th style={{ minWidth: "210px" }}>
                            <ColumnHeader header="2G Radio" />
                          </th>
                        )}
                        {cols["Radio 5G"] && (
                          <th style={{ minWidth: "210px" }}>
                            <ColumnHeader header="5G Radio" />
                          </th>
                        )}
                        {cols["Radio 6G"] && (
                          <th style={{ minWidth: "210px" }}>
                            <ColumnHeader header="6G Radio" />
                          </th>
                        )}
                        {cols.Venues && (
                          <th style={{ minWidth: "150px" }}>
                            <ColumnHeader
                              right
                              header="Venues"
                              attribute="Venues"
                              sort={sort}
                              setter={setSort}
                            />
                          </th>
                        )}
                        {cols["Pending Tasks"] && (
                          <th style={{ minWidth: "150px" }}>
                            <ColumnHeader header="Pending Task" />
                          </th>
                        )}
                        {cols.Infrastructure && (
                          <th style={{ minWidth: "150px" }}>
                            <ColumnHeader
                              right
                              header="Infrastructure"
                              attribute="Infrastructure"
                              sort={sort}
                              setter={setSort}
                            />
                          </th>
                        )}
                        {cols["Live Sites"] && (
                          <th style={{ minWidth: "125px" }}>
                            <ColumnHeader
                              right
                              header="Live Sites"
                              attribute="Live Sites"
                              sort={sort}
                              setter={setSort}
                            />
                          </th>
                        )}
                        {cols["Proposed Amt"] && (
                          <th style={{ minWidth: "150px" }}>
                            <ColumnHeader
                              right
                              header="Proposed Amt"
                            />
                          </th>
                        )}
                        {cols["Proposed Subs"] && (
                          <th style={{ minWidth: "170px" }}>
                            <ColumnHeader
                              right
                              header="Proposed Subs"
                            />
                          </th>
                        )}
                        {cols["In-Service"] && (
                          <th style={{ minWidth: "150px" }}>
                            <ColumnHeader
                              header="In Service"
                              attribute="In-Service"
                              sort={sort}
                              setter={setSort}
                            />
                          </th>
                        )}
                        {cols.Cost && (
                          <th style={{ minWidth: "150px" }}>
                            <ColumnHeader header="Cost" />
                          </th>
                        )}
                        {cols.Traffic && (
                          <th style={{ minWidth: "120px" }}>
                            <ColumnHeader header="Traffic" />
                          </th>
                        )}
                        {cols.Clients && (
                          <th style={{ minWidth: "120px" }}>
                            <ColumnHeader header="Clients" />
                          </th>
                        )}
                        {cols["UpTime"] && (
                          <th style={{ minWidth: "120px" }}>
                            <ColumnHeader
                              header="Up Time"
                              attribute={"UpTime"}
                              setter={setSort}
                              sort={sort}
                            />
                          </th>
                        )}
                        {cols.Release && (
                          <th style={{ minWidth: "120px" }}>
                            <ColumnHeader header="Release" />
                          </th>
                        )}
                        {listType == LIST_TYPES.INFRA_LIST && cols.Operations && (
                          <th className="" style={{ minWidth: "150px" }}>
                            <ColumnHeader header="Operations" />
                          </th>
                        )}
                        {cols["Serial Number"] && (
                          <th style={{ minWidth: "150px" }}>
                            <ColumnHeader header="Serial Number" />
                          </th>
                        )}
                        {cols["Asset Tag"] && (
                          <th style={{ minWidth: "150px" }}>
                            <ColumnHeader header="Asset Tag" />
                          </th>
                        )}
                        <th className="text-right">
                          <span
                            className="material-symbols-outlined cursor-pointer"
                            onClick={() =>
                              setIsColumnSelectorOpen((prevState) => !prevState)
                            }
                          >
                            settings
                          </span>
                        </th>
                      </tr>
                    </thead>
                    {list.length > 0 ? (
                      <tbody>
                        <SubList
                          isRoot
                          list={list}
                          setList={setList}
                          level={1}
                          notExpandable={listView === "flat" ? true : false}
                        />
                      </tbody>
                    ) : loading ? (
                      <tr>
                        <td
                          className="text-center pt-3"
                          style={{ height: listType == LIST_TYPES.MSP_LIST ? "15vh" : "45vh", backgroundColor: "white" }}
                          colSpan={20}
                        >
                          <Spinner color="primary" />
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td
                          className="text-center pt-3"
                          style={{ height: listType == LIST_TYPES.MSP_LIST ? "15vh" : "40vh", backgroundColor: "white" }}
                          colSpan={
                            Object.keys(cols)?.length + 2 > 0
                              ? Object.keys(cols)?.length + 2
                              : 10
                          }
                        >
                          No{" "}
                          {listType == LIST_TYPES.INFRA_LIST ?
                            "Infrastructure"
                            : listType == LIST_TYPES.VENUE_LIST
                              ? "Venue"
                              : (listType == LIST_TYPES.MSP_LIST && activeOrg.orgTypeId == BUSINESS)
                                ? "Venue"
                                : "Organization"}{" "}
                          Found
                        </td>
                      </tr>
                    )}
                    <Suspense fallback={<></>}>
                      <IfVisible
                        ifVisible={() => {
                          getList({ offset: list.length })
                        }}
                        disabled={loading || !hasMore || !(list?.length > 0)}
                      />
                    </Suspense>
                  </Table>
                </OrgListContext.Provider>
              </div>
            </InfiniteScroll>
          ) : (
            <Suspense fallback={<></>}>
              <MapWidget venues={list} loading={loading} />
            </Suspense>
          )}
        </div>}
      <Suspense fallback={<></>}>
        <DeleteModal
          isOpen={!!deleteModal}
          toggle={setDeleteModal}
          heading="Are You Sure?"
          info="Any Infrastructure on this venue will be moved to Default Venue."
          icon={
            deletionLoading ? (
              <Spinner className="me-25 m-1" size="lg" />
            ) : (
              "error"
            )
          }
        >
          <Button
            color="primary"
            onClick={() => {
              setDeleteModal(null);
            }}
          >
            Cancel
          </Button>
          <Button
            color="outline-danger"
            onClick={() => {
              deleteVenue(deleteModal);
            }}
          >
            Yes, Delete it
          </Button>
        </DeleteModal>
      </Suspense>
      <OrgDeleteModal deleteItem={deleteOrg} setDeleteItem={setDeleteOrg} onSubmit={() => { getList({ offset: 0 }); getCount(); }} />
      <DowngradeModal downgrade={downgrade} setDowngrade={setDowngrade} />
      {options != null && (
        <Suspense fallback={<></>}>
          <FirmwareReschedule
            options={options}
            setModal={setOptions}
            updateSnoozeDate={(snzDate, itemId, rescheduleInfra) => {
              if (rescheduleInfra) {
                if (options.setSubList) {
                  options.setSubList((prevState) => {
                    let index = prevState.findIndex((it) => it.infraItemId == itemId);
                    let newList = [...prevState];
                    if (index >= 0) newList[index].scheduleSnoozed = snzDate;
                    return [...newList];
                  });
                }
                else {
                  setList((prevState) => {
                    let index = prevState.findIndex((it) => it.infraItemId == itemId);
                    let newList = [...prevState];
                    if (index >= 0) newList[index].scheduleSnoozed = snzDate;
                    return [...newList];
                  });
                }
                return
              }
              if (options.setSubList) {
                options.setSubList((prevState) => {
                  let index = prevState.findIndex((it) => it.venueId == itemId);
                  let newList = [...prevState];
                  if (index >= 0) newList[index].scheduleSnoozed = snzDate;
                  return [...newList];
                });
              } else {
                setList((prevState) => {
                  let index = prevState.findIndex((it) => it.venueId == itemId);
                  let newList = [...prevState];
                  if (index >= 0) newList[index].scheduleSnoozed = snzDate;
                  return [...newList];
                });
              }
            }}
          />
        </Suspense>
      )}
    </div>
  );
};

OrgWidget.propTypes = {};

OrgWidget.defaultProps = {
  autoFocus: false,
  showVenueOptions: false,
  disableCollapseRoot: false,
  removeBlueHighlight: false,
  showOrgOptions: false
};

export default OrgWidget;
